<div id="aboutcontent" *ngIf="session.transparams.customer_shortname != ''" class="w3-modal-content w3-display-content w3-animate-zoom w3-card-4" [class]="session.transparams.customer_shortname == 'novacollege' ? 'w3-theme-l4' : 'w3-theme-l5'">
  <header class="w3-theme-l3 w3-display-container w3-border-bottom w3-border-theme">
    <fa-icon id="icon-close" [icon]="icons.fatimes" class="w3-display-topright w3-hover-shadow close" (click)="hideAbout()" title="{{ 'buttons.close' | translate: session.transparams }}"></fa-icon>
    <h2 class="w3-display-left w3-margin-left fullheight">{{'pagetitle.about' | translate: session.transparams }}</h2>
  </header>
  <div class="w3-container w3-margin-top">
    <p>{{ 'about.line1' | translate: session.transparams }}</p>
    <p>{{ 'about.line2' | translate: session.transparams }}</p>
    <ul>
      <li *ngIf="session.cardpreview">{{ 'about.list.cardpreview' | translate: session.transparams }}</li>
      <li *ngIf="!session.savephotoonserver">{{ 'about.list.cardstatus' | translate: session.transparams }}</li>
      <li *ngIf="session.uploadphoto && session.webcam">{{ 'about.list.uploadandwebcam' | translate: session.transparams }}</li>
      <li *ngIf="session.uploadphoto && !session.webcam">{{ 'about.list.onlyupload' | translate: session.transparams }}</li>
      <li *ngIf="session.webcam && !session.uploadphoto">{{ 'about.list.onlywebcam' | translate: session.transparams }}</li>
      <li *ngIf="!session.savephotoonserver">{{ 'about.list.carddata' | translate: session.transparams }}</li>
      <li *ngIf="session.cardactivate">{{ 'about.list.cardactivate' | translate: session.transparams }}</li>
      <li *ngIf="session.cardblock">{{ 'about.list.cardblock' | translate: session.transparams }}</li>
      <li *ngIf="session.cardrequest">{{ 'about.list.cardrequest' | translate: session.transparams }}</li>
      <li *ngIf="session.licenseplate">{{ 'about.list.licenseplate' | translate: session.transparams }}</li>
      <li *ngIf="session.chargingcard">{{ 'about.list.chargingcard' | translate: session.transparams }}</li>
    </ul>
    <div *ngIf="session.transparams.customer_shortname != 'Horizon' && session.transparams.customer_shortname != 'Inholland' && session.transparams.customer_shortname != 'novacollege' && session.transparams.customer_shortname != 'noorderpoort' ">
      <p *ngIf="session.transparams.customer_shortname != 'HVA' && session.transparams.customer_shortname != 'UVA' && session.transparams.customer_shortname != 'BAM' && !session.savephotoonserver ">{{ 'about.line3' | translate: session.transparams }}&nbsp;<a href="{{session.transparams.url}}" target="_blank"><b>{{ 'about.urltext' | translate: session.transparams }}</b></a></p>
      <p class="extralineheight">{{ 'about.line4' | translate:  session.transparams }}</p>
      <p>
        <span *ngIf="session.phone != ''">
          <fa-icon id="icon-phone" [icon]="icons.faphone"></fa-icon>
          &nbsp;<a href="tel:{{session.phone}}">{{session.phone}}</a>
          <span *ngIf="session.phone2">
            &nbsp;/&nbsp;<a href="tel:{{session.phone2}}">{{session.phone2}}</a>
         </span>
        </span>
        <br/>
        <span *ngIf="session.email != ''">
          <fa-icon id="icon-mail" [icon]="icons.faat"></fa-icon>&nbsp;<a href="mailto:{{session.email}}">{{session.email}}</a>
        </span>
      </p>
      <p *ngIf="session.terms">
        <b>{{ 'about.terms' | translate: session.transparams }}:&nbsp;</b>{{ 'about.click' | translate: session.transparams }}&nbsp;<a href="{{session.terms}}" target="_blank"><b>{{ 'about.urltext' | translate: session.transparams }}</b></a>
      </p>
    </div>
    <div *ngIf="session.transparams.customer_shortname == 'novacollege'">
      <p>{{ 'about.line3' | translate: session.transparams }}&nbsp;<a class="link" (click)="showMoreinfo()"><b>{{ 'about.urltext' | translate: session.transparams }}</b>.</a></p>
    </div>
    <div *ngIf="session.transparams.customer_shortname == 'Horizon'">
      <p>
        <span><b>Ben je student?</b></span><br/>
        <span>
          Je krijgt jouw Horizonpas thuisgestuurd nadat de onderwijsovereenkomst is ondertekend. Heb je hulp nodig, neem dan contact op met de studentenhelpdesk,
          via mail <a class="link" href="mailto:studentenhelpdesk@horizoncollege.nl">studentenhelpdesk@horizoncollege.nl</a>
          of bel <a class="link" href="tel:+31725476860">072 547 6860</a>
        </span>
      </p>
      <p>
        <span><b>Ben je medewerker?</b></span><br/>
        <span>Je krijgt jouw Horizonpas thuisgestuurd, tenzij je een externe medewerker bent. In dat geval kun je jouw Horizonpas ophalen bij het Servicepunt. Heb je hulp nodig, neem dan contact op met
          <a class="link" href="mailto:servicepunt@horizoncollege.nl">servicepunt@horizoncollege.nl</a>
        </span>
      </p>
      <p>Meer informatie over de Horizonpas vind je op ConnectMe in Weten & Regelen. Je kunt ook zoeken in de zoekbalk bovenaan.</p>
    </div>
    <p class="w3-right-align w3-padding w3-small">{{ 'footer.copyright' | translate: session.transparams }}</p>
  </div>
  <footer class="w3-theme-l3 w3-border-top w3-border-theme">
  </footer>
</div>
