import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }      from '@omnipas/services/icons/icons.service';
import { LoginService }      from '@omnipas/services/login/login.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

  username:    string  = '';
  password:    string  = '';
  initialized: boolean = false;

  eye;

  constructor ( public icons:          IconsService
              , public login:          LoginService
              , public loading:        LoadingService
              , public message:        MessageService
              , public pageheader:     PageHeaderService
              , public router:         RouterService
              , public session:        SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    this.initialized = false;

    this.eye = this.icons.faeye;

    await this.loading.preLoad();

    if ( !this.message.error ) {
      if ( this.session.useSaml ) {
        this.router.goTo('saml');
      } else if ( this.session.useSurf ) {
        this.router.goTo('surflogin');
      } else if ( this.session.useADFS ) {
        this.router.goTo('adfslogin');
      } else if ( this.session.autologin ) {
        this.username = this.session.username;
        this.password = this.session.password;
        this.login.login( this.username, this.password );
      } else {
        this.pageheader.setPageHeader( 'pagetitle.login', 'pagetext.login' );
        this.initialized = true;
      }
    }
  }

  togglePassword( bool ) {
    if ( bool ) {
      document.getElementById('password').setAttribute('type','text');
      this.eye = this.icons.faeyeslash;
    } else {
      document.getElementById('password').setAttribute('type','password');
      this.eye = this.icons.faeye;
    }

    document.getElementById('password').classList.toggle('visible');
  }

  submit() {
    if ( this.username != '' && this.password != '' ) {
      if ( this.session.useFramework ) {
        this.login.login( this.username, this.password );
      } else {
        this.login.newDatabaseLogin( this.username, this.password );
      }
    }
  }

  reset() {
    this.username = '';
    this.password = '';
  }

  requestNew() {
    this.router.requestPassword();
  }

  enableSubmit() {
    return this.username != '' && this.password != '';
  }

  enableReset() {
    return this.username != '' || this.password != '';
  }

  ngOnDestroy() {
    return;
  }

}
