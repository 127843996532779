import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';
import * as versionInfo      from "version-info";
import { format, parseISO }   from 'date-fns';

@Component({
  selector: 'omnipas-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {

  version: string = '';
  hash:    string = '';
  date:    string = '';
  info            = versionInfo;

  constructor ( public router:  RouterService
              , public session: SessionInfo
              ) {
  }

  ngOnInit() {
    this.version = this.info['versionInfo']['version'];
    this.hash    = this.info['versionInfo']['hash'];
    this.date    = format( parseISO ( this.info['versionInfo']['date'] ), 'dd-MM-yyyy');
  }

  showAbout() {
    this.router.showabout = true;
  }

  ngOnDestroy() {
    return;
  }

}
