import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }       from '@omnipas/services/card/card.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-blockcard',
  templateUrl: './blockcard.component.html'
})
export class BlockCardComponent implements OnInit, OnDestroy {

  initialized:    boolean = false;
  showLogout:     boolean = false;
  showRequestNew: boolean = false;

  constructor ( public card:       CardService
              , public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.blockcard', 'pagetext.blockcard' );
    }


    this.showRequestNew =  this.session.cardrequest
                        && (    this.list.getValue( this.session.personData, 'PhotoValidation', true ) == 'False'
                             || this.list.getValue( this.session.personData, 'Approved', true ) == 'True'
                           );

    this.initialized = true;

    this.router.toggleshowwait( false );

  }

  async blockcard() {

    this.router.toggleshowwait( true );

    if ( await this.card.update(6) ) {
      this.message.setSuccessMessage( 'block.success' );
    } else {
      this.message.setErrorMessage( 'block.failure' );
    }

    if ( !this.showRequestNew ) {
      this.setLogout();
    }

    this.router.toggleshowwait( false );

  }

  requestnew() {
    this.router.goTo('requestcard', true);
  }

  cancel() {
    this.router.backTo('welcome');
  }

  setLogout() {
    this.showLogout = true
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }
}
