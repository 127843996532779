export const environment = {
  production: true,
  apiurl: 'https://noorderpoortpas.nl/soap/',
  //apiurl: 'https://localhost/omnicard/angular/omnicard/apps/php/',
  apitype: 'php',
  uselog: true,
  configfile: 'noorderpoort',
  encrypt: true,
  config: {},
  ssl: true
};
