<header class="w3-display-container w3-border w3-theme-l3 w3-border-theme w3-round ontop">
  <div class="w3-display-left pointer" (click)="goHome();">
    <img id="logo"     class="w3-margin-left logo show"     [src]="language.getImageSrc('logo.png')" alt="logo.png" title="logo"    >
    <img id="logoicon" class="w3-margin-left logoicon hide" src="./assets/images/icon_omnicard.png"  alt="logoicon.png" title="logoicon">
  </div>
  <div class="w3-display-right w3-margin-right w3-round w3-card-2 w3-theme-l3">
    <div id="menutoggle" class="w3-right">
      <button class="w3-button w3-hover-theme w3-large" (click)="toggleMenu()" title="{{ 'header.menu' | translate: session.transparams }}">
        <fa-icon id="icon-menu" [icon]="icons.fabars"></fa-icon>
      </button>
    </div>
    <div id="menu" class="w3-right mobile-animate-right menu-items">
      <button class="w3-button w3-hover-theme w3-large" (click)="goHome()" title="{{ 'header.home' | translate: session.transparams }}" [disabled]="this.session.loginID == ''">
        <fa-icon id="icon-home" [icon]="icons.fahome"></fa-icon>
      </button>
      <omnipas-languagetoggle></omnipas-languagetoggle>
      <button *ngIf="session.transparams.customer_shortname != ''" class="w3-button w3-hover-theme w3-large" (click)="showAbout()" title="{{ 'header.about' | translate: session.transparams }}">
        <fa-icon id="icon-info" [icon]="icons.fainfo"></fa-icon>
      </button>
      <button class="w3-button w3-hover-theme w3-large" (click)="logout()" title="{{ 'header.logout' | translate: session.transparams }}">
        <fa-icon id="icon-logout" [icon]="icons.falogout"></fa-icon>
      </button>
    </div>
  </div>
</header>
