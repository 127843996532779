import { Component, OnInit, OnDestroy }   from '@angular/core';
import { CardService }         from '@omnipas/services/card/card.service';
import { ListService }         from '@omnilib/services/list/list.service';
import { LoadingService }      from '@omnipas/services/loading/loading.service';
import { MessageService }      from '@omnipas/services/message/message.service';
import { PageHeaderService }   from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }       from '@omnipas/services/router/router.service';
import { SessionInfo }         from '@omnipas/services/sessioninfo/sessioninfo';
import { VehicleDataService }  from '@omnipas/services/vehicledata/vehicledata.service';

@Component({
  selector: 'omnipas-licenseplate',
  templateUrl: './licenseplate.component.html'
})
export class LicensePlateComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  countrycode: string = 'NL';
  licenseplate: string = '';

  constructor ( public card:         CardService
              , public vehicledata:  VehicleDataService
              , public list:         ListService
              , public loading:      LoadingService
              , public message:      MessageService
              , public pageheader:   PageHeaderService
              , public router:       RouterService
              , public session:      SessionInfo
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if (    !this.session.licenseplate
         || this.session.loginID == ''
         || this.list.emptyList( this.session.cardholderData )
         || this.list.emptyList( this.session.personData )
       ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.licenseplate', 'pagetext.licenseplate' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  keyup() {
    this.licenseplate = this.licenseplate.toUpperCase();
  }

  async submit() {
    this.vehicledata.update ( this.countrycode, this.licenseplate, this.list.getValue ( this.session.personData, "ChargingCardNumber", true ) );
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }
 
}
