import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
import { EncryptService }    from '@omnilib/services/encrypt/encrypt.service';
import { IconsService }      from '@omnipas/services/icons/icons.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { OperationService }  from '@omnilib/services/operation/operation.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  initialized:     boolean = false;
  cardholderID:    string  = '';
  username:        string  = '';
  checkDate:       string  = '';
  password:        string  = '';
  repeatpassword:  string  = '';
  invalidLink:     boolean = true;

  eye;
  eyerepeat;

  constructor ( public  router:     RouterService
              , public  encrypt:    EncryptService
              , public  icons:      IconsService
              , public  loading:    LoadingService
              , public  message:    MessageService
              , public  operation:  OperationService
              , public  pageheader: PageHeaderService
              , public  route:      ActivatedRoute
              , public  session:    SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {

    this.initialized     = false;
    this.password        = '';
    this.repeatpassword  = '';

    this.eye       = this.icons.faeye;
    this.eyerepeat = this.icons.faeye;

    if ( this.session.endpoint == '' ) {
      await this.loading.preLoad();
    }

    const params = await this.route.params['value'];

    if ( params['token1'] && params['token1'] != '' ) {
      this.username = await this.encrypt.decrypt( params['token1'], true );
      this.username = this.username.replace(/"/g, '');

      const jsonParams = { 'Endpoint'  : this.session.endpoint
                         , 'Token'     : this.session.token
                         , 'Operation' : 'readCardholderLoginData'
                         , 'Fields'   : [ { 'name' : 'LoginID', 'value' : this.username } ]
                         };

      const result     = await this.operation.executeOperation( 'operation', jsonParams );

      if ( result['Result']['Code'] && result['Result']['Code'] == '0' ) {
          this.cardholderID = result['Result']['ResultSets']['CardholderID']['Value'];
          this.invalidLink  = false;
      } else {
        this.message.setErrorMessage( 'resetpassword.error.invalid' );
        this.invalidLink = true;
      }
    } else {
      this.message.setErrorMessage( 'resetpassword.error.invalid' );
    }

    if ( this.message.error ) {
      this.pageheader.setPageHeader( 'pagetitle.resetpassword', '' );
    } else {
      this.pageheader.setPageHeader( 'pagetitle.resetpassword', 'pagetext.resetpassword' );
    }

    this.initialized     = true;
  }

  togglePassword( bool ) {
    if ( bool ) {
      document.getElementById('password').setAttribute('type','text');
      this.eye = this.icons.faeyeslash;
    } else {
      document.getElementById('password').setAttribute('type','password');
      this.eye = this.icons.faeye;
    }

    document.getElementById('password').classList.toggle('visible');
  }

  toggleRepeatPassword( bool ) {
    if ( bool ) {
      document.getElementById('repeatpassword').setAttribute('type','text');
      this.eyerepeat = this.icons.faeyeslash;
    } else {
      document.getElementById('repeatpassword').setAttribute('type','password');
      this.eyerepeat = this.icons.faeye;
    }

    document.getElementById('repeatpassword').classList.toggle('visible');
  }

  reset() {
    this.password       = '';
    this.repeatpassword = '';
  }

  async submit() {
    if ( this.password != '' && this.repeatpassword != '') {
      if ( this.password == this.repeatpassword ) {

        /*
        const pass = await this.encrypt.hash(this.password);

        const jsonParams = { 'Endpoint'  : this.session.endpoint
                           , 'Token'     : this.session.token
                           , 'Operation' : 'updateCardholderPassword'
                           , 'Fields'   : [ { 'name' : 'CardholderID', 'value' : this.cardholderID }
                                          , { 'name' : 'CardholderPassword', 'value' : pass }
                                          ]
                           };

        */

        const jsonParams = { 'Endpoint'  : this.session.endpoint
                           , 'Token'     : this.session.token
                           , 'Operation' : 'updatePassword'
                           , 'Fields'   : [ { 'name' : 'CardholderID', 'value' : this.cardholderID }
                                          , { 'name' : 'Password', 'value' : this.password }
                                          ]
                           };

        const result = await this.operation.executeOperation( 'operation', jsonParams );

        console.log ( result );

        if ( result['Result']['Code'] ) {
          if ( result['Result']['Code'] == '0' ) {
            this.message.setSuccessMessage ( 'resetpassword.success' );
            this.pageheader.setPageHeader( 'pagetitle.resetpassword' );
          } else {
            this.message.setErrorMessage ( 'resetpassword.error.fail', true );
          }
        } else {
          this.message.setErrorMessage ( 'resetpassword.error.fail', true );
        }
      } else {
        this.message.setErrorMessage ( 'resetpassword.error.incorrect', true );
      }
    }
  }

  enableReset() {
    return this.password != '' || this.repeatpassword != '';
  }

  enableSubmit() {
    return this.password != '' && this.repeatpassword != '' && this.password == this.repeatpassword;
  }

  ngOnDestroy() {
    return;
  }

}
