import { Component, OnInit, OnDestroy } from '@angular/core';
import { EncryptService } from '@omnilib/services/encrypt/encrypt.service';
import { LanguageService } from '@omnipas/services/language/language.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }  from '@omnipas/services/loading/loading.service';
import { MessageService } from '@omnipas/services/message/message.service';
import { MailService } from '@omnilib/services/mail/mail.service';
import { OperationService } from '@omnilib/services/operation/operation.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { RouterService } from '@omnipas/services/router/router.service';
import { SessionInfo } from '@omnipas/services/sessioninfo/sessioninfo';
import { environment } from 'libs/omnilib/src/environments/dev/environment';

@Component({
  selector: 'omnipas-requestpassword',
  templateUrl: './requestpassword.component.html'
})
export class RequestPasswordComponent implements OnInit, OnDestroy {

  username:    string  = '';
  email:       string  = '';
  initialized: boolean = false;

  constructor ( public  encrypt:    EncryptService
              , public  language:   LanguageService
              , public  list:       ListService
              , public  loading:    LoadingService
              , public  mail:       MailService
              , public  message:    MessageService
              , public  operation:  OperationService
              , public  pageheader: PageHeaderService
              , public  router:     RouterService
              , public  session:    SessionInfo
              ) {
  }

  ngOnInit() {
    this.router.toggleshowwait( true );
    this.pageheader.setPageHeader( 'pagetitle.requestpassword', 'pagetext.requestpassword' );
    this.initialized     = true;
    this.router.toggleshowwait( false );
  }

  reset() {
    this.username = '';
    this.email    = '';
  }

  async submit( what ) {
    this.router.toggleshowwait( true );
    if ( this.username != '' && this.email != '') {
      try {
        const jsonParams = { 'Endpoint'  : this.session.endpoint
                           , 'Token'     : this.session.token
                           , 'Operation' : 'readCardholderLoginData'
                           , 'Fields'   : [ { 'name' : 'LoginID', 'value' : this.username } ]
                           };

        const result     = await this.operation.executeOperation( 'operation', jsonParams );

        if ( result['Result']['Code'] ) {
          if ( result['Result']['Code'] == '0' ) {
            const user      = result['Result']['ResultSets'];

            if ( user['Email']['Value'] != undefined && user['Email']['Value'] == this.email ) {
              const token    = await this.encrypt.encrypt( this.username, true );
              const url      = ( 'basehref' in environment ? environment['basehref'] : 'https://' + window.location.host + '/' ) +'resetpassword/'+token;

              this.session.cardholderData = result['Result']['ResultSets'];

              this.session.transparams.firstname = this.list.getValue( this.session.cardholderData, 'FirstName', true );
              this.session.transparams.fullname  = this.list.getValue( this.session.cardholderData, 'FirstName', true )  != '' ? this.list.getValue( this.session.cardholderData, 'FirstName', true ) : this.list.getValue( this.session.cardholderData, 'Initials', true );
              this.session.transparams.fullname += this.list.getValue( this.session.cardholderData, 'MiddleName', true ) != '' ? this.list.getValue( this.session.cardholderData, 'MiddleName', true ) : '';
              this.session.transparams.fullname += this.list.getValue( this.session.cardholderData, 'LastName', true );

              const body     = '<p>'  + this.language.getTranslation( 'requestpassword.email.line1' )
                             + '<br>' + this.language.getTranslation( 'requestpassword.email.line2' )
                             + '</p>'
                             + '<p><a href="'+url+'">'+url+'</a></p>'
                             + '<p>'  + this.language.getTranslation( 'requestpassword.email.line3' )
                             + '<br>' + this.language.getTranslation( 'requestpassword.email.line4' )
                             + '</p>'
                             + '<p>'  + this.language.getTranslation( 'requestpassword.email.line5' )
                             + '</p>'
                             ;

              await this.mail.send ( this.email
                                   , this.language.getTranslation( 'requestpassword.email.subject' )
                                   , body
                                   );
            }
          }
        }
      } catch(e) {
        //do nothing
      }
    }
    this.message.setSuccessMessage ('requestpassword.success');
    this.router.toggleshowwait( false );
  }

  enableReset() {
    return this.username != '' || this.email != '';
  }

  enableSubmit() {
    return this.username != '' && this.email != '';
  }

  ngOnDestroy() {
    return;
  }

}
