import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { InitializeService } from '@omnilib/services/initialize/initialize.service';
import { LanguageService } from '@omnipas/services/language/language.service';
import { ListService } from '@omnilib/services/list/list.service';
import { LogService } from '@omnipas/services/log/log.service';
import { MessageService } from '@omnipas/services/message/message.service';
import { OperationService } from '@omnilib/services/operation/operation.service';
import { PersonService } from '@omnipas/services/person/person.service';
import { PhotoService } from '@omnipas/services/photo/photo.service';
import { RouterService } from '@omnipas/services/router/router.service';
import { SessionInfo } from '@omnipas/services/sessioninfo/sessioninfo';
import { Title } from '@angular/platform-browser'


@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor(
    public http: HttpClient,
    public initialize: InitializeService,
    public language: LanguageService,
    public list: ListService,
    public log: LogService,
    public message: MessageService,
    public operation: OperationService,
    public photo: PhotoService,
    public person: PersonService,
    public router: RouterService,
    public session: SessionInfo,
    public title: Title
  ) {}

  async preLoad() {
    this.router.toggleshowwait( true );

    if (this.session.token == '') {
      this.log.initialize();

      let jsonParams = {};
      jsonParams = { Environment: this.session.configfile };

      //let result = this.session.apiurl != '' && this.session.apitype != 'php' ? await this.operation.executeOperation( 'initialize', jsonParams ) : await this.initialize.initializeConnection( jsonParams );

      let result = await this.initialize.initializeConnection(jsonParams);

      if (result['Result']['Code']) {
        if (result['Result']['Code'] == '0') {
          this.session.loglevels = result['loglevels'];
          this.session.timeout = result['timeout'];

          this.session.sessionid = result['SessionID'];
          this.session.token = result['Token'];
          this.session.endpoint = result['Endpoint'];
          // this.session.publicIP           = result['PublicIP'];

          this.session.licenseInfo = result['LicenseInfo'];
          this.session.versionInfo = result['VersionInfo'];

          this.session.useSaml = result['useSaml'];
          this.session.useADFS = result['useADFS'];
          this.session.useSurf = result['useSurf'];
          this.session.emailProvider = result['emailProvider'];
          this.session.ingenico = result['ingenico'];
          this.session.cardprices = result['cardprices'];
          this.session.publishPermission = result['publishPermission'];

          this.session.MFAemail = result['MFAemail'];
          this.session.MFAsms = result['MFAsms'];

          this.session.logoutbutton = result['showlogoutbutton'];
          this.session.digitalcard = result['digitalcard'];
          this.session.uploadphoto = result['uploadphoto'];
          this.session.webcam = result['webcam'];
          this.session.licenseplate = result['licenseplate'];
          this.session.chargingcard = result['chargingcard'];
          this.session.nameoncard = result['nameoncard'];
          this.session.nameoncardformats = result['nameoncardformats'];
          this.session.cardpreview = result['cardpreview'];
          this.session.cardaslink = result['cardaslink'];
          this.session.cardresult = result['cardresult'];
          this.session.cardactivate = result['cardactivate'];
          this.session.cardblock = result['cardblock'];
          this.session.carddeblock = result['carddeblock'];
          this.session.cardrequest = result['cardrequest'];
          this.session.scrollButtons = result['scrollButtons'];
          this.session.breadcrumbs = result['breadcrumbs'];
          this.session.versioninfooter = result['versioninfooter'];
          this.session.togglefont = result['togglefont'];
          this.session.firstnameonwelcome = result['firstnameonwelcome'];
          this.session.photorequirements = result['photorequirements'];
          this.session.deliveryaddress = result['deliveryaddress'];
          this.session.savephotoonserver = result['savephotoonserver'];
          this.session.langimage = result['langimage'];
          this.session.maintainance = result['maintainance'];
          this.session.changepublishpermission = result['changepublishpermission'];

          this.session.phone = result['phone'];
          this.session.phone2 = result['phone2'];
          this.session.email = result['email'];
          this.session.terms = result['terms'];

          this.session.photoResizeToWidth = result['photoResizeToWidth']
            ? result['photoResizeToWidth']
            : this.session.photoResizeToWidth;
          this.session.photoMiniatureWidth = result['photoMiniatureWidth']
            ? result['photoMiniatureWidth']
            : this.session.photoMiniatureWidth;
          this.session.photoAgreedNotOnFirstPage = result[
            'photoAgreedNotOnFirstPage'
          ]
            ? result['photoAgreedNotOnFirstPage']
            : this.session.photoAgreedNotOnFirstPage;

          this.session.transparams.product = result['product'];

          if ( this.session.transparams.product != '' ) {
            this.title.setTitle( this.session.transparams.product );
          }

          this.session.transparams.card = result['card'];

          this.session.transparams.customer_fullname =
            result['customer_fullname'];
          this.session.transparams.customer_shortname =
            result['customer_shortname'];
          this.session.transparams.response =
            '<a href="mailto:' +
            this.session.emailProvider['response'] +
            '">' +
            this.session.emailProvider['response'] +
            '</a>';
          this.session.transparams.url = result['url'];

          if (this.session.useSurf) {
            this.session.surfConfig = result['surfConfig'];
            //this.session.surfConfig['redirectUri'] = window.location.origin;
            //this.session.surfConfig['silentRefreshRedirectUri'] = window.location.origin + '/silent-refresh.html';
          }

          if (this.session.useADFS) {
            this.session.adfsConfig = result['adfsConfig'];
            //this.session.adfsConfig['redirectUri'] = window.location.origin;
            //this.session.adfsConfig['silentRefreshRedirectUri'] = window.location.origin + '/silent-refresh.html';
          }

          if (this.session.useSaml) {
            this.session.samlConfig = result['samlConfig'];
          }

          this.session.autologin = result['autologin'];
          this.session.useFramework = result['useFramework'];

          if (this.session.autologin || this.session.useFramework) {
            this.session.username = result['username'];
            this.session.password = result['password'];
          }

          this.session.languagesFromDB = result['languagesFromDB'];

          if (!this.session.languagesFromDB) {
            this.session.languages = result['languages'];

            this.language.changeLanguage(
              this.list.getValue(
                this.session.languages[0],
                'LanguageCode',
                true
              ),
              this.list.getValue(this.session.languages[0], 'Card', true),
              false
            );
          } else {
            jsonParams = {
              Endpoint: this.session.endpoint,
              Token: this.session.token,
              Operation: 'readLanguageList',
              Fields: [],
            };
            let langResult = await this.operation.executeOperation(
              'operation',
              jsonParams
            );

            if (langResult['Result']['Code']) {
              if (langResult['Result']['Code'] == '0') {
                this.session.languages = langResult['Result']['ResultSets'];
              } else {
                this.message.setErrorMessage('loading.error', false, true);
              }
            }
          }

          this.log.log(
            'session started with token' + ' ' + this.session.token,
            3
          );
        } else {
          this.message.setErrorMessage('loading.error', false, true);
        }
      } else {
        this.message.setErrorMessage('loading.error', false, true);
      }

      if (this.message.error) {
        this.log.log('error initializing:' + ' ' + result['Result'], 1);
        this.router.goTo('error');
      } else {
        this.router.starttimer();
      }
    }
  }

  /*
  async getPublicIP(): Promise<string> {
    let url = this.session.production ? 'https://api.ipify.org' : 'ipapi';
    return await this.http.get(url+'?format=json').pipe(map( res => { return res['ip'] } ) ).toPromise();
  }
  */

  async postLoad() {
    this.router.toggleshowwait( true );

    let result = false;

    if (this.list.emptyList(this.session.cardholderData) && !this.session.savephotoonserver) {
      result = await this.getCardHolderData();

      if (!result) {
        this.message.setErrorMessage('loading.notfound', false, true);
        this.router.logout();
        return;
      }
    }

    if (this.list.emptyList(this.session.personData) && !this.session.savephotoonserver) {
      result = await this.person.getPersonData();
      if (!result) {
        this.message.setErrorMessage('loading.notfound', false, true);
        this.router.logout();
        return;
      }
    }
  }

  async getLoginID($cardholderid) {
    if ($cardholderid != '') {
      let jsonParams = {
        Endpoint: this.session.endpoint,
        Token: this.session.token,
        Operation: 'readCardholderBasic',
        Fields: [{ name: 'CardholderID', value: $cardholderid }],
      };

      let result = await this.operation.executeOperation(
        'operation',
        jsonParams
      );

      if (result['Result']['Code'] && result['Result']['Code'] == '0') {
        let data = result['Result']['ResultSets'];

        if (data != undefined && !this.list.emptyList(data)) {
          this.session.loginID = this.list.getValue(data, 'LoginID', true);
        }
      }
    }
  }

  async getCardHolderData() {

    if (this.session.loginID != '') {
      let jsonParams = {
        Endpoint: this.session.endpoint,
        Token: this.session.token,
        Operation: 'readCardholderLoginData',
        Fields: [{ name: 'LoginID', value: this.session.loginID }],
      };

      let result = await this.operation.executeOperation(
        'operation',
        jsonParams
      );

      if (result['Result']['Code'] && result['Result']['Code'] == '0') {
        this.session.cardholderData = result['Result']['ResultSets'];

        if (
          this.session.cardholderData == undefined ||
          this.list.emptyList(this.session.cardholderData)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
    return false;
  }

  formatAmount($amount) {
    if ($amount == 0) {
      return '€ 0,00';
    } else {
      return (
        '€ ' +
        $amount.toString().substring(0, $amount.toString().length - 2) +
        ',' +
        $amount.toString().substring($amount.toString().length - 2)
      );
    }
  }

  async getDataFromClaims(claims) {

    /*
    urn:mace:dir:attribute-def:displayName
    urn:mace:dir:attribute-def:eduPersonAffiliation
    urn:mace:dir:attribute-def:mail
    urn:mace:dir:attribute-def:sn
    urn:mace:dir:attribute-def:givenName
    urn:mace:dir:attribute-def:uid
    */
    //this.log.log( JSON.stringify(claims), 3 );

    this.session.personData['LastName']          = { 'Value' : claims['family_name'] };
    this.session.personData['FirstName']         = { 'Value' : claims['given_name'] };
    this.session.personData['CardholderProfile'] = { 'Value' : claims['eduperson_affiliation'][0] };
    this.session.personData['NameOnCard']        = { 'Value' : claims['preferred_username'] };

    this.session.personData['PersonNumber']      = { 'Value' : claims['eduperson_principal_name'].replace('@student.inholland.nl', '').replace('@inholland.nl','') };
    this.session.personData['ProfileNumber']     = this.session.personData['PersonNumber'];
    this.session.personData['CardStartDate']     = '';

    this.session.cardholderData['Email']         = { 'value' : claims['email'] };
    this.session.cardholderData['LoginID']       = this.session.loginID;
    this.session.transparams.firstname           = claims['given_name'];
    this.session.personData['Photo']             = { 'Value' : await this.photo.getfile() };

    if ( this.session.transparams.customer_shortname == 'Inholland' ) {
      for ( let lang in this.session.languages ) {
        if ( this.session.languages[lang]['LanguageCode'] == this.language.currentLanguage ) {
          this.session.transparams.card = this.session.languages[lang]['Card'][this.session.personData['CardholderProfile']['Value']];
        }
      }
    }
  }

}
