import { Injectable } from '@angular/core';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { LogService }        from '@omnipas/services/log/log.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { OperationService }  from '@omnilib/services/operation/operation.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PersonService }     from '@omnipas/services/person/person.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Injectable({
  providedIn: 'root'
})
export class VehicleDataService {
  constructor( public list:           ListService
             , public loading:        LoadingService
             , public log:            LogService
             , public message:        MessageService
             , public operation:      OperationService
             , public pageheader:     PageHeaderService
             , public person:         PersonService
             , public router:         RouterService
             , public session:        SessionInfo
             ) {
  }

  async update( countrycode, licenseplate, chargingcardnumber ) {
    let operation  = 'updateVehicleData';

    let jsonParams = { 'Endpoint'  : this.session.endpoint
                     , 'Token'     : this.session.token
                     , 'Operation' : 'updateVehicleData'
                     , 'Fields'    : [ { 'name' : 'LoginID',            'value' : this.session.loginID }
                                     , { 'name' : 'LicensePlate',       'value' : licenseplate }
                                     , { 'name' : 'ChargingCardNumber', 'value' : chargingcardnumber }
                                     , { 'name' : 'CountryCode',        'value' : countrycode }
                                     ]
                     };

    let result = await this.operation.executeOperation( 'operation', jsonParams );

    if ( result['Result']['Code'] ) {
      if ( result['Result']['Code'] == '0' ) {
        await this.list.setValue( this.session.personData, 'LicensePlate', licenseplate );
        await this.list.setValue( this.session.personData, 'CountryCode',  countrycode );
        await this.list.setValue( this.session.personData, 'ChargingCardNumber', chargingcardnumber );
        this.message.setSuccessMessage ( 'licenseplate.result.success', false, true );
      } else {
        this.message.setErrorMessage ( result['Result']['Description'] , false, true );
        this.log.log( operation + ' failed: ' + result['Result']['Code'] + ' - ' + result['Result']['Description'], 1 );
      }
    } else {
      this.message.setErrorMessage ( 'licenseplate.result.error', false, true );
      this.log.log( operation + ' failed: ' + JSON.stringify(result), 1 );
    }
  }
}
