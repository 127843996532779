import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnDestroy, EventEmitter, HostListener } from '@angular/core';
import { LanguageService }   from '@omnipas/services/language/language.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { PhotoService }      from '@omnipas/services/photo/photo.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';
import { format, parse  }    from 'date-fns';

@Component({
  selector: 'omnipas-cardpreview',
  templateUrl: './cardpreview.component.html'
})
export class CardPreviewComponent implements OnInit, OnDestroy {

  @ViewChild('cardpreviewcontainer') public cardpreviewcontainer: ElementRef;
  @ViewChild('cardpreview')          public cardpreview:          ElementRef;
  @ViewChild('name')                 public name:                 ElementRef;

  firstName:      string  = '';
  fileinput:      string  = '';
  expdate:        string  = '';
  startdate:      string  = '';
  cardholderstartdate: string = '';

  cardOrigWidth:  number  = 0;
  cardOrigHeight: number  = 0;

  what:           string = '';
  warning:        string = '';

  initialized:    boolean = false;

  currentPhoto:   string = '';

  cardaslink:     boolean = false;
  title:          string = '';

  constructor ( public  language:   LanguageService
              , public  list:       ListService
              , public  photo:      PhotoService
              , public  router:     RouterService
              , public  session:    SessionInfo
              , public  renderer:   Renderer2
              ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {

    if ( !this.router.toosmall ) {

      const cardpreview          = this.cardpreview.nativeElement;
      const cardpreviewcontainer = this.cardpreviewcontainer.nativeElement;

      if ( this.cardOrigWidth == 0 ) {
        this.cardOrigWidth  = cardpreview.offsetWidth;
      }

      const newWidth  = Math.min( cardpreviewcontainer.offsetWidth, this.cardOrigWidth );
      const factor    = newWidth/cardpreview.offsetWidth;

      this.renderer.setStyle( cardpreview, 'width', newWidth + 'px');
      this.renderer.setStyle( cardpreview, 'height', ((cardpreview.offsetHeight*factor) + 'px'));

      for ( let child of cardpreview.children ) {
        if ( window.getComputedStyle(child, null).getPropertyValue('display') != 'none' ) {
          this.renderer.setStyle ( child
                                 , 'font-size'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('font-size')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'letter-spacing'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('letter-spacing')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'top'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('top')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'left'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('left')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'line-height'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('line-height')
                                                    , factor
                                                    )
                                 );

          if ( child.classList.contains('photo') ) {
            let newWidth = child.offsetWidth * factor;

            this.renderer.setStyle ( child
                                   , 'width'
                                   , newWidth + 'px'
                                   );
            this.renderer.setStyle ( child
                                   , 'height'
                                   , ( child.classList.contains('photo') ? (newWidth/3)*4 : child.offsetHeight * factor ) + 'px'
                                   );

          } else if ( child.classList.contains('barcode') ) {
            this.renderer.setStyle ( child
                                   , 'width'
                                   , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('width')
                                                      , factor
                                                      )
                                   );

            this.renderer.setStyle ( child
                                   , 'height'
                                   , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('height')
                                                      , factor
                                                      )
                                   );

            for ( let barcode_child of child.children ) {
              this.renderer.setStyle ( barcode_child
                                     , 'width'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('width')
                                                        , factor
                                                        )
                                     );

              this.renderer.setStyle ( barcode_child
                                     , 'height'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('height')
                                                        , factor
                                                        )
                                     );

              this.renderer.setStyle ( barcode_child
                                     , 'font-size'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('font-size')
                                                        , factor
                                                        )
                                     );

              this.renderer.setStyle ( barcode_child
                                     , 'letter-spacing'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('letter-spacing')
                                                        , factor
                                                        )
                                     );


            }
          }
        }
      }
    }
  }

  getNewValue( $oldValue, $factor ) {
    return ( +$oldValue.replace('px', '') * $factor) + 'px';
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.getScreenSize();

    this.router.toggleshowwait( false );

  }

  async initialize() {
    this.initialized = false;

    try {
      this.expdate = format( parse( this.list.getValue( this.session.personData, 'CardEndDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.expdate = '';
    }

    try {
      this.startdate = format( parse( this.list.getValue( this.session.personData, 'CardStartDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.startdate = '';
    }

    try {
      this.cardholderstartdate = format( parse( this.list.getValue( this.session.personData, 'CardholderStartDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.cardholderstartdate = this.list.getValue( this.session.personData, 'CardholderStartDate', true );
    }

    if ( this.photo.photoResult != undefined && this.photo.photoResult != '' ) {
      if ( this.photo.photoResult.indexOf('data:image/jpg;base64,') == -1 ) {
        this.currentPhoto = 'data:image/jpg;base64,'+this.photo.photoResult.replace('data:image/jpeg;base64,', '');
      } else {
        this.currentPhoto = this.photo.photoResult;
      }
    } else if ( this.list.getValue( this.session.personData, 'Photo', true ) != '' ) {
      this.currentPhoto = 'data:image/jpg;base64,'+this.list.getValue( this.session.personData, 'Photo', true );
    } else {
      this.currentPhoto = 'assets/images/photo_template.jpg';
    }

    this.cardaslink = this.session.cardpreview && this.session.cardaslink;

    this.title = this.cardaslink ? 'welcome.cardinfo' : '';

    this.initialized = true;
  }

  getClass() {
     let classList = this.list.getValue( this.session.personData, 'CardholderProfile', true ).toLowerCase();
     classList += this.session.langimage ? ' ' + this.language.currentLanguage : '';
     classList += this.cardaslink ? ' w3-hover-shadow pointer' : '';
     classList += this.list.getValue( this.session.personData, 'CardholderBusinessUnit', true ).toLowerCase();
     return classList;
  }

  showInfo() {
    if ( this.cardaslink ) {
      this.router.goTo('cardinfo');
    }
  }

  ngOnDestroy() {
    return;
  }
}
