import { Component, OnInit, OnDestroy }     from '@angular/core';
import { ActivatedRoute }        from '@angular/router';
import { EncryptService }        from '@omnilib/services/encrypt/encrypt.service';
import { LoadingService }        from '@omnipas/services/loading/loading.service';
import { MessageService }        from '@omnipas/services/message/message.service';
import { PageHeaderService }     from '@omnipas/services/pageheader/pageheader.service';
import { PersonService }         from '@omnipas/services/person/person.service';
import { RouterService }         from '@omnipas/services/router/router.service';
import { SessionInfo }           from '@omnipas/services/sessioninfo/sessioninfo';
import { environment }           from 'libs/omnilib/src/environments/dev/environment';

@Component({
  selector: 'omnipas-saml',
  templateUrl: './saml.component.html'
})
export class SamlComponent implements OnInit, OnDestroy {

  claims = {};
  initialized: boolean = false;

  constructor ( public  route:          ActivatedRoute
              , public  encrypt:        EncryptService
              , public  loading:        LoadingService
              , public  message:        MessageService
              , public  pageheader:     PageHeaderService
              , public  person:         PersonService
              , public  router:         RouterService
              , public  session:        SessionInfo
              ) {

    this.session.loggingIn = true;
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );
    await this.initialize();
    this.initialized = true;
    this.session.loggingIn = false;
    this.router.toggleshowwait( false );
  }

  async initialize() {
    const params      = this.route.params;
    const queryparams = this.route.queryParams;

    if ( this.session.token == '' ) {
      await this.loading.preLoad();
    }

    let jsonParams = params['value']['token1'] ? params['value']['token1'] : queryparams['value']['token1'] ? queryparams['value']['token1'] : null;

    if ( jsonParams != null ) {
      let result = JSON.parse( await this.encrypt.decrypt( await decodeURIComponent(jsonParams) ) );

      this.session.loginID = await this.getLoginID(result);

      if ( this.session.loginID != null && this.session.loginID != '' ) {
        this.session.loggingIn = true;

        let cardholderdatafound = await this.loading.getCardHolderData();
        let persondatafound     = await this.person.getPersonData()

        if ( !cardholderdatafound || !persondatafound ) {
          this.session.loggingIn = false;
          this.message.setErrorMessage( 'loading.notfound', false, true );
          this.router.goTo('error');
        } else {
          this.session.loggedIn  = true;
          this.session.loggingIn = false;
          this.router.goTo('welcome');
        }
      }
    }
  }

  async getLoginID(claims) {

    if ( this.session.samlConfig['loginItems'] != undefined ) {
      for ( let loginItem of this.session.samlConfig['loginItems'] ) {
        if ( claims[loginItem['item']] != undefined && claims[loginItem['item']][0] != '' ) {
          return claims[loginItem['item']][0];
        }
      }
    }
    return null;
  }

  submit() {
    if ( environment.production ) {
      window.location.href = environment.apiurl+'saml.php';
    } else {
      window.location.href = 'https://leijgraaf.mijnomnipas.nl/soap/saml.php';
    }
  }

  ngOnDestroy() {
    return;
  }

}
