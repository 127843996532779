import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterService } from '@omnipas/services/router/router.service';
import { SessionInfo } from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  constructor ( public router: RouterService
              , public session: SessionInfo
              ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    return;
  }

}
