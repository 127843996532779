import { Injectable }  from '@angular/core';
import { TransParamsInterface } from '@omnipas/interfaces/transparams.interface';
import { environment } from 'libs/omnilib/src/environments/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionInfo {
  production:                boolean = environment.production;
  encrypt:                   boolean = environment.encrypt;
  apiurl:                    string  = environment.apiurl;
  apitype:                   string  = environment.apitype;
  uselog:                    boolean = environment.uselog;
  configfile:                string  = environment.configfile;
  phone:                     string  = '+31 172 520 540';
  phone2:                    string  = '';
  email:                     string  = 'info@omnicard.nl';
  terms:                     string  = '';
  userID:                    string  = '0';
  loggedIn:                  boolean = false;
  loginChecked:              boolean = false;
  loggingIn:                 boolean = false;
  sessionid:                 string  = '';
  token:                     string  = '';
  endpoint:                  string  = '';
  //publicIP:           string  = '';
  languageID:                string  = '';
  useSaml:                   boolean = false;
  useSurf:                   boolean = false;
  useADFS:                   boolean = false;
  loginID:                   string  = '';
  autologin:                 boolean = false;
  useFramework:              boolean = false;
  username:                  string  = '';
  password:                  string  = '';
  amount:                    string  = '0000';
  languagesFromDB:           boolean = false;
  MFAemail:                  boolean = false;
  MFAsms:                    boolean = false;
  publishPermission:         boolean = false;
  logoutbutton:              boolean = false;
  digitalcard:               boolean = false;
  licenseplate:              boolean = false;
  chargingcard:              boolean = false;
  nameoncard:                boolean = false;
  uploadphoto:               boolean = false;
  webcam:                    boolean = false;
  cardpreview:               boolean = false;
  cardaslink:                boolean = true;
  cardresult:                boolean = false;
  cardblock:                 boolean = false;
  carddeblock:               boolean = false;
  cardactivate:              boolean = false;
  cardrequest:               boolean = false;
  scrollButtons:             boolean = false;
  breadcrumbs:               boolean = false;
  versioninfooter:           boolean = false;
  togglefont:                boolean = false;
  firstnameonwelcome:        boolean = true;
  photorequirements:         boolean = false;
  deliveryaddress:           boolean = false;
  photoResizeToWidth:        number;
  photoMiniatureWidth:       number;
  photoAgreedNotOnFirstPage: boolean = false;
  timeout:                   number  = 0;
  savephotoonserver:         boolean = false;
  langimage:                 boolean = false;
  maintainance:              boolean = false;
  changepublishpermission:   boolean = false;

  transparams:        TransParamsInterface = { product            : 'OmniPAS'
                                             , customer_fullname  : ''
                                             , customer_shortname : ''
                                             , url                : ''
                                             , card               : 'kaart'
                                             , response           : ''
                                             , firstname          : ''
                                             , fullname           : ''
                                             , amount             : ''
                                             };
  version           = {};
  licenseInfo       = {};
  versionInfo       = {};
  metaData          = {};
  entityContent     = {};
  userFunctions     = {};
  samlConfig        = {};
  surfConfig        = {};
  adfsConfig        = {};
  languages         = [];
  nameoncardformats = [];
  personData        = {};
  emailProvider     = {};
  cardholderData    = {};
  ingenico          = {};
  cardprices        = {};
  loglevels         = { "tofile" : 0, "prefix" : "", "toconsole" : 0 }
}
