import { Component, OnInit, OnDestroy }   from '@angular/core';
import { ListService }         from '@omnilib/services/list/list.service';
import { LoadingService }      from '@omnipas/services/loading/loading.service';
import { MessageService }      from '@omnipas/services/message/message.service';
import { PageHeaderService }   from '@omnipas/services/pageheader/pageheader.service';
import { PhotoService }        from '@omnipas/services/photo/photo.service';
import { RouterService }       from '@omnipas/services/router/router.service';
import { SessionInfo }         from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-photopermissions',
  templateUrl: './photopermissions.component.html'
})
export class PhotoPermissionsComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  countrycode: string = 'NL';
  licenseplate: string = '';

  constructor ( public list:         ListService
              , public loading:      LoadingService
              , public message:      MessageService
              , public pageheader:   PageHeaderService
              , public photo:        PhotoService
              , public router:       RouterService
              , public session:      SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if (    !this.session.changepublishpermission
         || this.session.loginID == ''
         || this.list.emptyList( this.session.cardholderData )
         || this.list.emptyList( this.session.personData )
       ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.photopermissions', 'pagetext.photopermissions' );
      await this.photo.read();
    }

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async submit() {
    await this.photo.save(true);
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  showpopup() {
    this.router.showpermissionsinfo = true;
  }

  ngOnDestroy() {
    return;
  }
}
