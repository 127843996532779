import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }      from '@omnipas/services/icons/icons.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit, OnDestroy {

  constructor ( public icons:   IconsService
              , public message: MessageService
              , public session: SessionInfo
              ) {
  }

  ngOnInit() {
  }

  getClass() {
    let classes = this.message.animate? 'w3-animate-right ' : '';

    switch (true) {
      case this.message.success :
        classes += 'success';
        break;
      case this.message.error :
        classes += 'error';
        break;
      case this.message.info :
        classes += 'info';
        break;
      case this.message.warning :
        classes += 'warning';
        break;
      case this.message.question :
        classes += 'question';
        break;
      case this.message.title :
        classes += 'title';
        break;
      default :
        classes += 'other';
        break;
    }

    return classes;
  }

  close() {
    this.message.clearMessage();
  }

  ngOnDestroy() {
    return;
  }
}
