import { Injectable } from '@angular/core';
import { EncryptService }   from '@omnilib/services/encrypt/encrypt.service';
import { LanguageService }  from '@omnipas/services/language/language.service';
import { ListService }      from '@omnilib/services/list/list.service';
import { LoadingService }   from '@omnipas/services/loading/loading.service';
import { LogService }       from '@omnipas/services/log/log.service';
import { OperationService } from '@omnilib/services/operation/operation.service';
import { SessionInfo }      from '@omnipas/services/sessioninfo/sessioninfo';
import { format, toDate  }  from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PaymentService  {

  ingenico          = {};
  url:       string = '';

  constructor( public encrypt:   EncryptService
             , public language:  LanguageService
             , public list:      ListService
             , public loading:   LoadingService
             , public log:       LogService
             , public operation: OperationService
             , public session:   SessionInfo
             ) {
  }

  async getSign( $fields, $sign ) {
    let shastring: string = '';

    Object.keys($fields).sort().forEach(function(key) {
      if ( $fields[key] != '' ) {
        shastring += `${key}=${$fields[key]}${$sign}`;
      }
    });

    return await this.encrypt.shasign( shastring );
  }

  getLanguagueCode( $lang ){
    switch($lang) {
      case 'eng' :
      case 'en'  :
        return 'en_US';
        break;
      case 'nl'  :
      case 'nld' :
      case 'nl2' :
      default    :
        return 'nl_NL';
        break;
    }
  }

  getAddress() {
    let address = '';
    address += this.list.getValue( this.session.personData, 'Street', true ) + ' ' + this.list.getValue( this.session.personData, 'Number', true ) + ' ' + this.list.getValue( this.session.personData, 'Suffix', true );
    return address.trim();
  }

  async initialize() {
    let shasign: string  = '';
    this.url       = this.list.getValue( this.session.ingenico, 'url', true );

    this.ingenico = {
      'PSPID'          : this.list.getValue( this.session.ingenico, 'pspid', true ),
      'ORDERID'        : this.createOrderID(),
      'AMOUNT'         : this.session.amount,
      'CURRENCY'       : this.list.getValue( this.session.ingenico, 'currency', true ),
      'LANGUAGE'       : this.getLanguagueCode( this.language.currentLanguage ),
      'CN'             : this.session.transparams.fullname,
      'EMAIL'          : this.list.getValue( this.session.personData, 'Email', true ),
      'OWNERZIP'       : this.list.getValue( this.session.personData, 'PostalCode', true ),
      'OWNERADDRESS'   : this.getAddress(),
      'OWNERCTY'       : this.list.getValue( this.session.personData, 'City', true ),
      'OWNERTOWN'      : this.list.getValue( this.session.personData, 'City', true ),
      'COM'            : "",
      'TITLE'          : this.language.getTranslation('payment.requestNewCard'),
      'BGCOLOR'        : this.list.getValue( this.session.ingenico, 'bgcolor', true ),
      'TXTCOLOR'       : this.list.getValue( this.session.ingenico, 'txtcolor', true ),
      'TBLBGCOLOR'     : this.list.getValue( this.session.ingenico, 'tblbgcolor', true ),
      'TBLTXTCOLOR'    : this.list.getValue( this.session.ingenico, 'tbltxtcolor', true ),
      'BUTTONBGCOLOR'  : this.list.getValue( this.session.ingenico, 'buttonbgcolor', true ),
      'BUTTONTXTCOLOR' : this.list.getValue( this.session.ingenico, 'buttontxtcolor', true ),
      'FONTTYPE'       : this.list.getValue( this.session.ingenico, 'fonttype', true ),
      'PM'             : this.list.getValue( this.session.ingenico, 'pm', true ),
      'HOMEURL'        : this.list.getValue( this.session.ingenico, 'homeurl', true ),
      'ACCEPTURL'      : this.list.getValue( this.session.ingenico, 'accepturl', true ),
      'DECLINEURL'     : this.list.getValue( this.session.ingenico, 'declineurl', true ),
      'EXCEPTIONURL'   : this.list.getValue( this.session.ingenico, 'exceptionurl', true ),
      'CANCELURL'      : this.list.getValue( this.session.ingenico, 'cancelurl', true ),
      'SHASIGN'        : ''
    };

    shasign = await this.getSign( this.ingenico, this.list.getValue( this.session.ingenico, 'sha_in_key', true ) );

    this.ingenico['SHASIGN'] = shasign;
  }

  createOrderID() {
    let orderid = this.list.getValue( this.session.ingenico, 'orderid', true );

    return ( orderid == '' ? 'CARDREQUEST_' : orderid )
         + format(toDate(new Date()),'ddMMyyyyHHmmss')
         + '&_&'
         + this.list.getValue( this.session.personData, 'CardholderID', true )
    ;
  }

  async checkSignOut( $fields, $sign ) {
    let shasign:   string = '';
    let shastring: string = '';

    let cleanfields = {};

    Object.keys($fields).forEach(function(key) {
     cleanfields[decodeURIComponent(key).toUpperCase()] = decodeURIComponent($fields[key]);
    });

    Object.keys(cleanfields).sort().forEach(function(key) {
      if ( key == 'SHASIGN' ) {
        shasign = cleanfields[key];
      } else if ( cleanfields[key] != '' ) {
        shastring += `${key.toUpperCase()}=${cleanfields[key]}${$sign}`;
      }
    });

    this.log.log ( 'checksign ingenico ' + shasign, 3 );
    this.log.log ( 'checksign omnipas ' + await this.encrypt.shasign(shastring), 3 );

    return shasign == await this.encrypt.shasign(shastring);
  }

  async updatecardholdernote ( note ) {
    if ( note != '' && !this.list.emptyList( this.session.personData ) ) {

      let oldnote = this.list.getValue( this.session.personData, 'Note', true );

      let newnote = ( oldnote != '' ? oldnote + '\n\n' : '' ) + format(toDate(new Date()),'yyyy-MM-dd HH:mm:ss') + ' ' + note;

      let jsonParams = { 'Endpoint'  : this.session.endpoint
                       , 'Token'     : this.session.token
                       , 'Operation' : 'updateCardholderNote'
                       , 'Fields'    : [ { 'name' : 'CardholderID', 'value' : this.list.getValue( this.session.personData, 'CardholderID', true ) }
                                       , { 'name' : 'Note',         'value' :   newnote }
                                       ]
                       };

      let result = await this.operation.executeOperation( 'operation', jsonParams );
    }
  }

}
