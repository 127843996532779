import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }       from '@omnipas/services/card/card.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PaymentService }    from '@omnipas/services/payment/payment.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-nameoncard',
  templateUrl: './nameoncard.component.html'
})
export class NameOnCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  constructor ( public card:       CardService
              , public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public payment:    PaymentService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if (    !this.session.nameoncard
         || this.session.loginID == ''
         || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData )
       ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.nameoncard', 'pagetext.nameoncard' );

      for ( let format of this.session.nameoncardformats ) {
        format.format = format.format.replace( /<<Initials>>/gi,   this.list.getValue ( this.session.personData, "Initials",   true ));
        format.format = format.format.replace( /<<FirstName>>/gi,  this.list.getValue ( this.session.personData, "FirstName",  true ));
        format.format = format.format.replace( /<<MiddleName>>/gi, this.list.getValue ( this.session.personData, "MiddleName", true ));
        format.format = format.format.replace( /<<LastName>>/gi,   this.list.getValue ( this.session.personData, "LastName",   true ));
        format.format = format.format.replace( /\s+/gi, ' ');
        format.format = format.format.trim();
      }
    }

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  setSelected( format ) {
    return format == this.list.getValue ( this.session.personData, "NameOnCard", true ) ? 'selected' : '';
  }


  async submit() {
    /*
    if (    !this.list.emptyList ( this.session.cardprices )
         && this.payment.getCardPrice( this.list.getValue( this.session.personData, 'CardholderProfileID', true ) ) != '0000' ) {
      this.router.goTo("payment");
    } else {
      if ( await this.card.create() ) {
        this.message.setSuccessMessage ( 'requestcard.success');
      } else {
        this.message.setErrorMessage( 'requestcard.failure' );
      }
    }
    */
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
