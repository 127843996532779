import { Injectable }       from '@angular/core';
import { HttpClient,
         HttpHeaders }      from '@angular/common/http';
import { EncryptService }   from '@omnilib/services/encrypt/encrypt.service';
import { OperationService } from '@omnilib/services/operation/operation.service';
import { environment } from 'libs/omnilib/src/environments/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  headers: HttpHeaders = new HttpHeaders;

  constructor ( public http: HttpClient
              , public encrypt: EncryptService
              , public operation: OperationService
              ) {
  }

  async send( $to, $subject, $message, $from = '' ) {
    this.headers.set('Access-Control-Allow-Origin', '*');

    let url = environment.production ? environment.apiurl : 'wsdl';

    const jsonParams = { 'what'          : 'sendmail'
                       , 'to'            : $to
                       , 'subject'       : $subject
                       , 'message'       : $message
                       , 'from'          : $from
                       };

    let params = await this.encrypt.encrypt(jsonParams);

    try {
      await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
    } catch(e) {
      console.log ( e );
    }
  }

}
