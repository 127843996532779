import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService }   from '@omnipas/services/language/language.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-languagetoggle',
  templateUrl: './languagetoggle.component.html'
})
export class LanguageToggleComponent implements OnInit, OnDestroy {
  constructor ( public language: LanguageService
              , public list:     ListService
              , public session:  SessionInfo
              ) {
  }

  ngOnInit() {
  }

  checkCurrentLanguage($lang) {
    if ( this.language.currentLanguage == $lang.toLowerCase() ) {
      return 'hide';
    } else {
      return 'show fullwidth';
    }
  }

  ngOnDestroy() {
    return;
  }
}
