<div *ngIf="session.maintainance">
  <omnipas-maintainance></omnipas-maintainance>
</div>
<div>
  <omnipas-showwait></omnipas-showwait>
</div>
<div *ngIf="router.showabout" id="about" class="w3-modal w3-modal-theme">
  <omnipas-about></omnipas-about>
</div>
<div *ngIf="router.showmoreinfo" id="moreinfo" class="w3-modal w3-modal-theme">
  <omnipas-moreinfo></omnipas-moreinfo>
</div>
<div *ngIf="router.showpermissionsinfo" id="permissionsinfo" class="w3-modal w3-modal-theme">
  <omnipas-permissionsinfo></omnipas-permissionsinfo>
</div>
<div *ngIf="router.showphotorequirements" id="photorequirements" class="w3-modal w3-modal-theme">
  <omnipas-photorequirements></omnipas-photorequirements>
</div>
<div
  *ngIf="initialized && session.scrollButtons && !router.showdigitalcard"
  class="w3-center w3-padding"
  id="scrolltotop"
  [class]="showUpArrow ? '' : 'w3-hide'"
  (click)="scrollToTop()"
>
  <fa-icon id="icon-up" [icon]="icons.fachevronup" class="w3-large w3-wide w3-text-white"></fa-icon>
</div>
<div
  *ngIf="!session.maintainance"
  id="main"
  [class]="router.showdigitalcard ? 'digitalcard ' : 'main w3-container w3-responsive w3-display-topmiddle w3-margin-top w3-margin-bottom w3-mobile'"
>
  <omnipas-header *ngIf="!router.showdigitalcard"></omnipas-header>
  <div
    class="w3-theme-l4 w3-responsive w3-mobile"
    [class]="router.showdigitalcard ? 'digitalcard ' : 'content w3-display-container w3-border w3-margin-top w3-margin-bottom w3-theme-l4 w3-border-theme w3-round-large'"
  >
    <router-outlet></router-outlet>
    <div
      *ngIf="session.breadcrumbs && !router.showdigitalcard"
      class="w3-responsive w3-margin-left w3-display-bottomleft w3-margin-top breadcrumb"
    >
      <omnipas-breadcrumb></omnipas-breadcrumb>
    </div>
  </div>
  <div
    *ngIf="!router.showdigitalcard"
    class="toosmallblock w3-responsive w3-display-container w3-border w3-margin-top w3-margin-bottom w3-theme-l4 w3-border-theme w3-round-large"
  >
    <p>{{ 'errors.screentoosmall' | translate: session.transparams }}</p>
  </div>
  <omnipas-footer *ngIf="!router.showdigitalcard"></omnipas-footer>
</div>
<div
  *ngIf="initialized && session.scrollButtons && !router.showdigitalcard"
  class="w3-center w3-padding"
  id="scrolltobottom"
  [class]="showDownArrow ? '' : 'w3-hide'"
  (click)="scrollToBottom()"
>
  <fa-icon id="icon-down" [icon]="icons.fachevrondown" class="w3-large w3-wide w3-text-white"></fa-icon>
</div>
