import { Component, OnInit, OnDestroy }     from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthConfig, OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { LoadingService }        from '@omnipas/services/loading/loading.service';
import { MessageService }        from '@omnipas/services/message/message.service';
import { PageHeaderService }     from '@omnipas/services/pageheader/pageheader.service';
import { PersonService }         from '@omnipas/services/person/person.service';
import { RouterService }         from '@omnipas/services/router/router.service';
import { SessionInfo }           from '@omnipas/services/sessioninfo/sessioninfo';
import { environment } from 'libs/omnilib/src/environments/dev/environment';

@Component({
  selector: 'omnipas-adfslogin',
  templateUrl: './adfslogin.component.html'
})
export class ADFSLoginComponent implements OnInit, OnDestroy {

  claims = {};
  initialized: boolean = false;
  error: boolean = false;

  constructor ( public  http:           HttpClient
              , public  loading:        LoadingService
              , public  message:        MessageService
              , public  oauthService:   OAuthService
              , public  pageheader:     PageHeaderService
              , public  person:         PersonService
              , public  router:         RouterService
              , public  session:        SessionInfo
              ) {

    this.session.loggingIn = true;

    this.initializeOAuthService();
  }

  async initializeOAuthService() {
    this.session.loggingIn = true;

    if ( this.session.endpoint == '' ) {
      await this.loading.preLoad();
    }

    let authConfig: AuthConfig = {};

    authConfig.issuer                   = this.session.adfsConfig['issuer'];
    authConfig.clientId                 = this.session.adfsConfig['clientId'];
    authConfig.redirectUri              = this.session.adfsConfig['redirectUri'];
    authConfig.silentRefreshRedirectUri = this.session.adfsConfig['silentRefreshRedirectUri'];
    authConfig.scope                    = this.session.adfsConfig['scope'];
    authConfig.responseType             = this.session.adfsConfig['responseType'];
    authConfig.oidc                     = this.session.adfsConfig['oidc'];
    authConfig.requireHttps             = this.session.adfsConfig['requireHttps'];
    authConfig.showDebugInformation     = this.session.adfsConfig['showDebugInformation'];
    authConfig.loginUrl                 = authConfig.issuer + '/oauth2/authorize';
    authConfig.logoutUrl                = authConfig.issuer + '/oauth2/logout';

    if ( this.session.adfsConfig['version'] == "2016" && environment.apiurl != '' && environment.apitype != 'php' ) {
      let jwksUrl = authConfig.issuer + '/discovery/keys';
      authConfig.jwks = JSON.parse( await this.http.post( environment.apiurl+'jwks', { content: jwksUrl } , { responseType: 'text' } ).toPromise());
    }

    await this.oauthService.configure(authConfig);

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    await this.oauthService.setStorage(localStorage);

    if ( this.session.adfsConfig['version'] != "2016" ) {
      await this.oauthService.loadDiscoveryDocument();
    }

    await this.oauthService.tryLogin()
    // If we're still not logged in yet, try with a silent refresh:
      .then(() => {
        if (!this.oauthService.hasValidAccessToken()) {
          return this.oauthService.silentRefresh();
        }
      })

      // Get username, if possible.
      //.then(() => this.oauthService.loadUserProfile())
      .then( async () => this.claims = await this.oauthService.getIdentityClaims())
      .then( async () => this.session.loginID = await this.getLoginID())
      .then( async () => {
        if ( this.session.loginID != '' ) {
          this.session.loggingIn = true;

          let cardholderFound = await this.loading.getCardHolderData();
          let personFound = await this.person.getPersonData();

          if ( !cardholderFound || !personFound ) {
            this.session.loggingIn = false;
            this.message.setErrorMessage( 'loading.notfound', false, true );
          } else {
            this.session.loggedIn  = true;
            this.session.loggingIn = false;
            this.router.goTo('welcome');
          }
        } else {
          await this.oauthService.initImplicitFlow();
        }
      });

    this.session.loggingIn = false;
  }

  ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;
    this.pageheader.setPageHeader( 'pagetitle.adfslogin', 'pagetext.adfslogin' );
    this.initialized = true;

    this.router.toggleshowwait( false );

  }

  submit() {
    this.session.loggingIn = true;
    this.oauthService.initImplicitFlow();
  }

  getLoginID() {
    if ( this.claims != undefined ) {
      if ( this.session.adfsConfig['loginItem'] != undefined ) {
        if ( this.claims[this.session.adfsConfig['loginItem']] != undefined ) {
          if ( this.session.adfsConfig['loginItem'] == 'uids' ) {
            return this.claims[this.session.adfsConfig['loginItem']][0];
          } else if ( this.session.adfsConfig['loginItem'] == 'email' ) {
            return this.claims[this.session.adfsConfig['loginItem']].substring(0, this.claims[this.session.adfsConfig['loginItem']].indexOf("@"));
          } else {
            return this.claims[this.session.adfsConfig['loginItem']];
          }
        }
      }
    }

    return '';
  }

  ngOnDestroy() {
    return;
  }
}
