<div class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <table class="w3-table w3-theme-l4">
    <tr>
      <td>
        <input
          id        = "agreed"
          name      = "agreed"
          type      = "checkbox"
          class     = "w3-check w3-check-theme w3-margin-right"
          title     = ""
          (click)   = "photo.togglePhotoAgreed()"
          [checked] = "photo.photoAgreed ? 'checked' : ''"
          tabindex  = "1"
          disabled
        />
      </td>
      <td>
        <label for="agreed" innerHTML="{{ 'welcome.agreed' | translate: session.transparams }}<br/>{{ 'photopermissions.attention' | translate: session.transparams }}"></label>
      </td>
    </tr>
    <tr>
      <td>
        <input
          id        = "publishPermission"
          name      = "publishPermission"
          type      = "checkbox"
          class     = "w3-check w3-margin-right"
          title     = "{{'photo.edit.publishPermission' | translate: session.transparams}}"
          (click)   = "photo.togglePublishPermission()"
          [checked] = "photo.publishPermission ? 'checked' : ''"
        />
      </td>
      <td>
        <label for="publishPermission" innerHTML="{{ 'photo.edit.publishPermission' | translate: session.transparams }}"></label>&nbsp;
        <span *ngIf="session.changepublishpermission"><a class="link" innerHTML="{{ 'photo.edit.moreinfo' | translate: session.transparams }}" (click)="showpopup()"></a></span>
      </td>
    </tr>
    <tr>
      <td colspan="2">&nbsp;</td>
    </tr>
    <tr>
      <td colspan="2" *ngIf="!(message.success || message.error)">
        <input
          type        = "button"
          class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round w3-margin-right"
          name        = "cancel"
          value       = "{{ 'buttons.back' | translate: session.transparams }}"
          title       = "{{ 'buttons.back' | translate: session.transparams }}"
          (click)     = "cancel()"
        >
        <input
          type        = "button"
          class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
          name        = "submit"
          value       = "{{ 'buttons.save' | translate: session.transparams }}"
          title       = "{{ 'buttons.save' | translate: session.transparams }}"
          (click)     = "submit()"
        >
      </td>
    </tr>
    <tr>
      <td colspan="2" *ngIf="(message.success || message.error)">
        <input
          type        = "button"
          class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
          name        = "logout"
          value       = "{{ 'buttons.logout' | translate: session.transparams }}"
          title       = "{{ 'buttons.logout' | translate: session.transparams }}"
          (click)     = "logout()"
        >
      </td>
    </tr>
  </table>
</div>
