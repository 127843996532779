import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute }       from '@angular/router';
import { CardService }          from '@omnipas/services/card/card.service';
import { ListService }          from '@omnilib/services/list/list.service';
import { LoadingService }       from '@omnipas/services/loading/loading.service';
import { LogService }           from '@omnipas/services/log/log.service';
import { MessageService }       from '@omnipas/services/message/message.service';
import { PageHeaderService }    from '@omnipas/services/pageheader/pageheader.service';
import { PaymentService }       from '@omnipas/services/payment/payment.service';
import { RouterService }        from '@omnipas/services/router/router.service';
import { SessionInfo }          from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  result:      string  = '';
  showLogout:  boolean = false;

  @ViewChild('ingenicoForm') ingenicoForm : ElementRef;

  constructor ( public  route:          ActivatedRoute
              , public  card:           CardService
              , public  list:           ListService
              , public  loading:        LoadingService
              , public  log:            LogService
              , public  message:        MessageService
              , public  pageheader:     PageHeaderService
              , public  payment:        PaymentService
              , public  router:         RouterService
              , public  session:        SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    try {
      await this.initialize();
    } catch(e) {
      this.message.setErrorMessage( 'payment.fail' );
      this.showLogout = false;
    }
    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async initialize() {
    this.router.paying = true;

    this.pageheader.setPageHeader( 'pagetitle.payment', 'pagetext.payment' );

    const params      = this.route.params;
    const queryparams = this.route.queryParams;

    if ( params['value']['token1'] ) {

      this.result = await decodeURIComponent(params['value']['token1']);

      await this.loading.preLoad();

      this.log.log ( 'result ingenico ' + this.result, 3 );

      if ( queryparams['value'] ) {
        let checksign    = await this.payment.checkSignOut( queryparams['value'], this.list.getValue( this.session.ingenico, 'sha_out_key' ) );
        let orderID      = queryparams['value']['orderID'];
        let cardholderid = orderID.substring(orderID.indexOf('&_&') + 3);

        if ( checksign ) {
          try {
            await this.loading.getLoginID( cardholderid );
            await this.loading.postLoad();
            await this.payment.updatecardholdernote ( this.result + ' payment' );

          } catch(e) {
            this.message.setErrorMessage( 'payment.fail' );
            this.showLogout = true;
          }

          switch ( this.result ) {
            case 'success' :
              if ( await this.card.create() ) {
                this.message.setSuccessMessage ( 'payment.success');
              } else {
                this.message.setErrorMessage( 'payment.fail' );
              }
              this.showLogout = true;
              break;
            case 'cancel' :
              this.message.setErrorMessage( 'payment.cancel' );
              this.showLogout = true;
              break;
            case 'decline' :
              this.message.setErrorMessage( 'payment.decline' );
              this.showLogout = true;
              break;
            case  'fail' :
            default :
              this.message.setErrorMessage( 'payment.fail' );
              this.showLogout = true;
              break;
          }
        } else {
          this.message.setErrorMessage( 'payment.fail' );
          this.showLogout = true;
        }
      }
    } else {
      if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
        this.router.logout();
      } else  if ( this.list.emptyList ( this.session.ingenico ) || this.list.emptyList ( this.session.cardprices ) ) {
        this.message.setErrorMessage( 'payment.config' );
        this.showLogout = true;
      } else {
        await this.payment.initialize();
        this.result = 'start';
        this.showLogout = false;
      }
    }
  }

  async submit() {
    await this.payment.updatecardholdernote ( 'start payment' );
    let form = this.ingenicoForm.nativeElement;
    form.submit();
  }

  cancel() {
    this.message.setErrorMessage( 'payment.cancel' );
  }

  logout() {
    this.router.paying = false;
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
