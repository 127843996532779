import { Component, OnInit, OnDestroy } from '@angular/core';
import { ListService }       from '@omnilib/services/list/list.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-cardinfo',
  templateUrl: './cardinfo.component.html'
})
export class CardInfoComponent implements OnInit, OnDestroy {

  initialized: boolean = false

  constructor ( public list:       ListService
              , public pageheader: PageHeaderService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  ngOnInit() {
    this.initialized = false;

    if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.cardinfo', 'pagetext.cardinfo' );
    }

    this.router.toggleshowwait( false );
    this.initialized = true;
  }

  back() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }
}
