import { Component, OnInit, OnDestroy } from '@angular/core';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { OperationService }  from '@omnilib/services/operation/operation.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PhotoService }      from '@omnipas/services/photo/photo.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-deliveryaddress',
  templateUrl: './deliveryaddress.component.html'
})
export class DeliveryAddressComponent implements OnInit, OnDestroy {

  initialized:       boolean = false;

  postalcode:        string = '';
  housenumber:       string = '';
  housenumbersuffix: string = '';
  street:            string = '';
  city:              string = '';

  change:            boolean = false;
  addressfound:      boolean = false;
  saved:             boolean = false;
  searching:         boolean = false;

  constructor ( public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public operation:  OperationService
              , public pageheader: PageHeaderService
              , public photo:      PhotoService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.deliveryaddress', 'pagetext.deliveryaddress' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  logout() {
    this.router.logout();
  }

  toggleChange() {
    this.pageheader.setPageHeader( 'pagetitle.deliveryaddress', 'deliveryaddress.line5' );
    this.change = true;
  }

  formatZipCode() {
    if ( this.postalcode.length == 4 ) {
      this.postalcode = this.postalcode + ' ';
      return;
    }

    if ( this.postalcode.length != 7 || this.housenumber.length == 0  ) {
      this.street       = '';
      this.city         = '';
      this.addressfound = false;
      return;
    }

    //this.searchAddress( disctrictcode, lettercombination, this.housenumber );
  }

  async searchAddress() {

    if ( this.postalcode.length != 7 || this.housenumber.length == 0  ) {
      this.street       = '';
      this.city         = '';
      this.addressfound = false;
      this.searching    = false;
      return;
    }

    this.router.toggleshowwait( true );

    this.searching = true;

    let postalcode        = this.postalcode.replace(' ', '');
    let districtcode      = postalcode.substr(0,4);
    let lettercombination = postalcode.substr(4,2);

    let operation = 'readAddressByPcHnr';

    let fields = [ { 'name' : 'DistrictCode',      'value' : districtcode      }
                 , { 'name' : 'LetterCombination', 'value' : lettercombination }
                 , { 'name' : 'HouseNumber',       'value' : this.housenumber  }
               ];

    let jsonParams = { 'Endpoint'  : this.session.endpoint
                     , 'Token'     : this.session.token
                     , 'Operation' : operation
                     , 'Fields'    : fields
                     };

    let result = await this.operation.executeOperation( 'operation', jsonParams );

    if ( result['Result']['Code'] && result['Result']['Code'] == '0' ) {
      let address = result['Result']['ResultSets'];

      if ( this.list.emptyList( address ) ) {
        this.addressfound = false;
        this.searching    = false;
        return;
      }

      this.street       = this.list.getValue( address, 'Street', true );
      this.city         = this.list.getValue ( address, 'City', true ).toUpperCase();
      this.addressfound = true;
      this.searching    = false;
    }

    this.router.toggleshowwait( false );
  }

  async submit() {
    this.router.toggleshowwait( true );

    let operation = 'createDeliveryAddress';

    let fields = [ { 'name' : 'CardholderID', 'value' : this.list.getValue( this.session.personData, 'CardholderID', true )     }
                 , { 'name' : 'AddressLine1', 'value' : this.street     + ' ' + this.housenumber + ' ' + this.housenumbersuffix }
                 , { 'name' : 'AddressLine2', 'value' : this.postalcode + '  ' + this.city                                      }
               ];

    let jsonParams = { 'Endpoint'  : this.session.endpoint
                     , 'Token'     : this.session.token
                     , 'Operation' : operation
                     , 'Fields'    : fields
                     };

    let result = await this.operation.executeOperation( 'operation', jsonParams );

    if ( result['Result']['Code'] && result['Result']['Code'] == '0' ) {
      this.message.setSuccessMessage('deliveryaddress.success');
      this.saved = true;
    } else {
      this.message.setErrorMessage('deliveryaddress.failure');
    }

    this.router.toggleshowwait( false );
  }

  back() {
    this.postalcode        = '';
    this.housenumber       = '';
    this.housenumbersuffix = '';
    this.street            = '';
    this.city              = '';

    this.change            = false;
    this.addressfound      = false;
    this.saved             = false;
    this.searching         = false;
    this.message.error     = false;
  }

  ngOnDestroy() {
    return;
  }

}
