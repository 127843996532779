import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Observable, timer } from 'rxjs';
import { map, catchError, switchMap, share } from 'rxjs/operators';
import { LocalStorageService } from '@omnipas/services/localstorage/localstorage.service';
import { SessionInfo } from '@omnipas/services/sessioninfo/sessioninfo';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  initialLanguage = 'nl';
  currentLanguage = 'nl';

  savedlang       = '';

  initialCard     = 'kaart';

  constructor ( public translate: TranslateService
              , public session:   SessionInfo
              , public localstorage: LocalStorageService
              ) {
  }

  initialize() {
    this.savedlang = this.localstorage.getData( 'lang' );
    this.currentLanguage = this.savedlang == undefined || this.savedlang == null || this.savedlang == '' ? this.initialLanguage : this.savedlang;
    this.session.transparams.card = this.initialCard;
    this.translate.setDefaultLang(this.currentLanguage);
  }

  changeLanguage( $lang, $card, $overwrite = true ) {
    if ( $lang == '' || !$overwrite ) {
      this.currentLanguage = this.savedlang == undefined || this.savedlang == null || this.savedlang == '' ? this.initialLanguage : this.savedlang;
    } else {
      this.currentLanguage = $lang.toLowerCase();
      this.localstorage.saveData ( 'lang', $lang );
    }

    if ( $card == '' ) {
      if ( this.session.transparams.customer_shortname == 'Inholland' ) {
        for ( let l in this.session.languages ) {
          if ( this.session.languages[l]['LanguageCode'] == this.currentLanguage ) {
            if ( this.session.personData['CardholderProfile'] == undefined ) {
              this.session.transparams.card = this.session.languages[l]['Card']['student'];
            } else {
              this.session.transparams.card = this.session.languages[l]['Card'][this.session.personData['CardholderProfile']['Value']];
            }
          }
        }
      } else {
        this.session.transparams.card = this.initialCard;
      }
    } else {
      this.session.transparams.card = $card;
    }

    this.translate.setDefaultLang(this.currentLanguage);
  }

  getTranslation( $text, $type: string = 'I' ): string {

    let result: string = '';

    this.translate.get( $text, this.session.transparams ).subscribe((res: string) => {
      result = res;
    });

    switch($type) {
      case "C" :
        return result.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
      case "I" :
        return result[0].toUpperCase() + result.slice(1);
        break;
      case "L" :
        return result.toLowerCase();
        break;
      case "U" :
        return result.toUpperCase();
        break;
      default :
        return result;
        break;
    }
  }

  getImageSrc( img ) {
    let url = 'assets/images/' + ( this.session.langimage ? ( this.currentLanguage != '' ? this.currentLanguage : this.initialLanguage ) + '/' : '' ) +img;
    return url;
  }
}
