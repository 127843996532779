import { Injectable } from '@angular/core';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { LogService }        from '@omnipas/services/log/log.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { OperationService }  from '@omnilib/services/operation/operation.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PersonService }     from '@omnipas/services/person/person.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor( public list:           ListService
             , public loading:        LoadingService
             , public log:            LogService
             , public message:        MessageService
             , public operation:      OperationService
             , public pageheader:     PageHeaderService
             , public person:         PersonService
             , public router:         RouterService
             , public session:        SessionInfo
             ) {
  }

  async update(status, remarks = '' ) {
    if ( status != '' && !this.list.emptyList( this.session.personData ) ) {

      let operation  = 'updateCard';
      let jsonParams = { 'Endpoint'  : this.session.endpoint
                       , 'Token'     : this.session.token
                       , 'Operation' : operation
                       , 'Fields'    : [ { 'name' : 'UserID',           'value' : this.session.userID }
                                       , { 'name' : 'StatusID',         'value' : status }
                                       , { 'name' : 'CardID',           'value' : this.list.getValue( this.session.personData, 'CardID', true ) }
                                       , { 'name' : 'CardCardholderID', 'value' : this.list.getValue( this.session.personData, 'CardholderID', true ) }
                                       , { 'name' : 'Remarks',          'value' : remarks }
                                       ]
                       };

      let result = await this.operation.executeOperation( 'operation', jsonParams );

      if ( result['Result']['Code'] ) {
        if ( result['Result']['Code'] == '0' ) {
          await this.list.setValue( this.session.personData, 'CardStatusID', status );

          if ( await this.person.getPersonData() ) {
            this.log.log( operation + ' succeeded', 3 );
            return true;
          } else {
            this.log.log( operation + ' failed: ' + 'Person data not found', 1 );
          }
        } else {
          this.log.log( operation + ' failed: ' + result['Result']['Code'] + ' - ' + result['Result']['Description'], 1 );
        }
      } else {
        this.log.log( operation + ' failed: ' + JSON.stringify(result), 1 );
      }
    }
    return false;
  }

  async create() {

    if ( this.session.loginID == '' ) {
      return false;
    }

    let personFound: boolean = !this.list.emptyList( this.session.personData );

    if ( !personFound ) {
      personFound = await this.person.getPersonData();
    }

    if ( personFound ) {
      let operation = 'createCard';
      let jsonParams = { 'Endpoint'  : this.session.endpoint
                       , 'Token'     : this.session.token
                       , 'Operation' : 'createCard'
                       , 'Fields'    : [ { 'name' : 'UserID',           'value' : this.session.userID }
                                       , { 'name' : 'CardCardholderID', 'value' : this.list.getValue( this.session.personData, 'CardholderID', true ) }
                                       ]
                       };

      let result = await this.operation.executeOperation( 'operation', jsonParams );

      if ( result['Result']['Code'] ) {
        if ( result['Result']['Code'] == '0' ) {
          await this.list.setValue( this.session.personData, 'CardStatusID', status );

          if ( await this.person.getPersonData() ) {
            this.log.log( operation + ' succeeded', 3 );
            return true;
          } else {
            this.log.log( operation + ' failed: ' + 'Person data not found', 1 );
          } 
        } else {
          this.log.log( operation + ' failed: ' + result['Result']['Code'] + ' - ' + result['Result']['Description'], 1 );
        }
      } else {
        this.log.log( operation + ' failed: ' + JSON.stringify(result), 1 );
      }
    }

    return false;
  }
}
