<div *ngIf="initialized && !session.loggingIn" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>

  <div *ngIf="!message.error" class="w3-margin">
    <input
      type        = "button"
      id          = "login"
      name        = "login"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      title       = "{{ 'buttons.surfconext' | translate: session.transparams }}"
      value       = "{{ 'buttons.surfconext' | translate: session.transparams }}"
      (click)     = "submit()"
    >
  </div>
</div>
