import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthConfig, OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { LoadingService }        from '@omnipas/services/loading/loading.service';
import { MessageService }        from '@omnipas/services/message/message.service';
import { PageHeaderService }     from '@omnipas/services/pageheader/pageheader.service';
import { PersonService }         from '@omnipas/services/person/person.service';
import { RouterService }         from '@omnipas/services/router/router.service';
import { SessionInfo }           from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-surflogin',
  templateUrl: './surflogin.component.html'
})
export class SurfLoginComponent implements OnInit, OnDestroy {

  claims = {};
  initialized: boolean = false;

  constructor ( public  loading:        LoadingService
              , public  message:        MessageService
              , public  oauthService:   OAuthService
              , public  pageheader:     PageHeaderService
              , public  person:         PersonService
              , public  router:         RouterService
              , public  session:        SessionInfo
              ) {

    this.session.loggingIn = true;

    this.connect();
  }

  async connect() {
    this.session.loggingIn = true;

    if ( this.session.token == '' ) {
      await this.loading.preLoad();
      this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );
    }

    let authConfig: AuthConfig = {};

    authConfig.issuer       = this.session.surfConfig['issuer'];
    authConfig.clientId     = this.session.surfConfig['clientId'];
    authConfig.redirectUri  = this.session.surfConfig['redirectUri'];
    authConfig.logoutUrl    = this.session.surfConfig['logoutUrl'];
    //authConfig.silentRefreshRedirectUri = this.session.surfConfig['silentRefreshRedirectUri'];
    authConfig.scope        = this.session.surfConfig['scope'];
    authConfig.requireHttps = false;

    await this.oauthService.configure(authConfig);

    await this.oauthService.setStorage(localStorage);
    // Load information from Auth0 (could also be configured manually)
    this.oauthService.loadDiscoveryDocument()
      // See if the hash fragment contains tokens (when user got redirected back)
      .then( async() => await this.oauthService.tryLogin())

      // If we're still not logged in yet, try with a silent refresh:
      .then( async() => {
        if (!await this.oauthService.hasValidAccessToken()) {
          return this.oauthService.silentRefresh();
        }
      })

      // Get username, if possible.
      .then( async () => await this.oauthService.loadUserProfile())
      .then( async () => this.claims = await this.oauthService.getIdentityClaims())
      .then( async () => await this.getLoginID())
      .then( async () => {
        this.session.maintainance = false;

        if ( this.session.loginID != '' ) {
          this.session.loggingIn = true;

          if ( this.session.savephotoonserver ) {
            this.session.loggedIn  = true;
            this.session.loggingIn = false;
            this.router.goTo('welcome');
          } else {
            if ( await this.loading.getCardHolderData() ) {
              if ( await this.person.getPersonData() ) {
                this.session.loggedIn  = true;
                this.session.loggingIn = false;
                this.router.goTo('welcome');
              } else {
                this.session.loggingIn = false;
                this.message.setErrorMessage( 'loading.notfound', false, true );
              }
            } else {
              this.session.loggingIn = false;
              this.message.setErrorMessage( 'loading.notfound', false, true );
            }
          }
        } else {
          await this.oauthService.initImplicitFlow();
        }
      });

    this.session.loggingIn = false;

    this.oauthService.setupAutomaticSilentRefresh();
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );
    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async submit() {
    await this.oauthService.initImplicitFlow();
  }

  async getLoginID() {
    console.log ( this.claims );

    if ( this.session.surfConfig['loginItem'] != undefined ) {
      if ( this.claims[this.session.surfConfig['loginItem']] != undefined ) {
        if ( this.session.surfConfig['loginItem'] == 'uids' ) {
          this.session.loginID = this.claims[this.session.surfConfig['loginItem']][0];
        } else if ( this.session.surfConfig['loginItem'] == 'email' ) {
          this.session.loginID = this.claims[this.session.surfConfig['loginItem']].substring(0, this.claims[this.session.surfConfig['loginItem']].indexOf("@"));
        } else {
          this.session.loginID = this.claims[this.session.surfConfig['loginItem']];
        }
      }
    }

    if ( this.session.savephotoonserver ) {
      await this.loading.getDataFromClaims( this.claims );
    }
  }

  ngOnDestroy() {
    return;
  }

}
