import { Injectable }              from '@angular/core';
import { EncryptService }          from '@omnilib/services/encrypt/encrypt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionInfo }             from '@omnipas/services/sessioninfo/sessioninfo';
import { environment }             from 'libs/omnilib/src/environments/dev/environment';
import { format, toDate  }         from 'date-fns';
import { DeviceDetectorService }   from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  headers: HttpHeaders = new HttpHeaders;
  deviceInfo: string = '';

  constructor ( public encrypt: EncryptService
              , public http: HttpClient
              , public session: SessionInfo
              , public device: DeviceDetectorService
              ) {}

  async initialize() {
    let device = this.device.getDeviceInfo();
    this.deviceInfo = device.deviceType + ' ' + device.os + ' ' + device.os_version + ' ' + device.browser + ' ' + device.browser_version;
  }

  async log( $message, $loglevel = 0 ) {

    if ( $loglevel == 0 ) {
      return;
    }

    if ( $loglevel <= this.session.loglevels["toconsole"] ) {
      console.log ( $message )
    }

    if ( environment.uselog && environment.apitype == 'php' && $loglevel <= this.session.loglevels["tofile"] ) {

      if ( this.deviceInfo == '' ) {
        await this.initialize();
      }

      this.headers.set('Access-Control-Allow-Origin', '*');
      let url = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'message' : format(toDate(new Date()),'yyyy-MM-dd HH:mm:ss') + ' ' + this.session.sessionid + ' ' + this.session.loginID + ' ' + $message + ' ' + this.deviceInfo
                       , 'file'    : this.session.loglevels["prefix"] + format(toDate(new Date()),'yyyyMMdd')
                       , 'what'    : 'log'
                       }
      let params = await this.encrypt.encrypt(jsonParams);
      try {
        await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      } catch(e) {
        if ( this.session.loglevels["toconsole"] > 0 ) {
          console.log ( 'logging niet mogelijk');
        }
      }
    }
  }
}
