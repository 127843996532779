import { Injectable }       from '@angular/core';
import { ListService }      from '@omnilib/services/list/list.service';
import { OperationService } from '@omnilib/services/operation/operation.service';
import { SessionInfo }      from '@omnipas/services/sessioninfo/sessioninfo';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(
    public list: ListService,
    public operation: OperationService,
    public session: SessionInfo
  ) {}

  async getPersonData() {
    if (this.session.loginID != '') {
      let jsonParams = {
        Endpoint: this.session.endpoint,
        Token: this.session.token,
        Operation: 'readPersonByLoginID',
        Fields: [{ name: 'LoginID', value: this.session.loginID }],
      };

      let result = await this.operation.executeOperation(
        'operation',
        jsonParams
      );

      if (result['Result']['Code'] && result['Result']['Code'] == '0') {
        this.session.personData = result['Result']['ResultSets'];

        if (
          this.session.personData == undefined ||
          this.list.emptyList(this.session.personData)
        ) {
          return false;
        } else {
          if (this.session.firstnameonwelcome) {
            this.session.transparams.firstname = this.list.getValue(
              this.session.personData,
              'FirstName',
              true
            );
          }

          this.session.transparams.fullname =
            this.list.getValue(this.session.personData, 'FirstName', true) != ''
              ? this.list.getValue(this.session.personData, 'FirstName', true)
              : this.list.getValue(this.session.personData, 'Initials', true);
          this.session.transparams.fullname +=
            this.list.getValue(this.session.personData, 'MiddleName', true) !=
            ''
              ? ' ' +
                this.list.getValue(this.session.personData, 'MiddleName', true)
              : '';
          this.session.transparams.fullname +=
            ' ' + this.list.getValue(this.session.personData, 'LastName', true);

          this.session.amount = await this.list.getValue(
            this.session.cardprices,
            await this.list
              .getValue(this.session.personData, 'CardholderProfileID', true)
              .toString(),
            true
          );
          if (this.session.amount === '') {
            this.session.amount = this.list.getValue(
              this.session.cardprices,
              'default',
              true
            );
          }

          if (this.session.amount === '') {
            this.session.amount = '0000';
          }

          this.session.transparams.amount = this.formatAmount(
            this.session.amount
          );

          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  formatAmount($amount) {
    if ($amount == 0) {
      return '€ 0,00';
    } else {
      return (
        '€ ' +
        $amount.toString().substring(0, $amount.toString().length - 2) +
        ',' +
        $amount.toString().substring($amount.toString().length - 2)
      );
    }
  }
}
