import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';


@Component({
  selector: 'omnipas-fonttoggle',
  templateUrl: './fonttoggle.component.html'
})
export class FontToggleComponent implements OnInit, OnDestroy {

  font: string = 'ubuntu';

  constructor ( public session:  SessionInfo
              ) {
  }

  ngOnInit() {
  }

  togglefont($font) {
    let body        = document.getElementById('body');
    body.classList.remove("opensans");
    body.classList.remove("lato");
    body.classList.remove("ubuntu");
    body.classList.add($font);
    this.font = $font;
  }

  checkcurrentfont($font) {
    return this.font == $font ? 'selectedfont' : '';
  }

  ngOnDestroy() {
    return;
  }

}
