import { Injectable }              from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptService }          from '@omnilib/services/encrypt/encrypt.service';
import { ListService }             from '@omnilib/services/list/list.service';
import { LogService }              from '@omnipas/services/log/log.service';
import { MessageService }          from '@omnipas/services/message/message.service';
import { OperationService }        from '@omnilib/services/operation/operation.service';
import { PersonService }           from '@omnipas/services/person/person.service';
import { RouterService }           from '@omnipas/services/router/router.service';
import { SessionInfo }             from '@omnipas/services/sessioninfo/sessioninfo';
import { environment }             from 'libs/omnilib/src/environments/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  headers:           HttpHeaders = new HttpHeaders;

  photo;
  photoResult;
  photoMiniature;
  photoAgreed:       boolean = false;
  publishPermission: boolean = false;
  saving:            boolean = false;
  webcamPhoto:       boolean = false;
  firstphoto:        boolean = false;

  mimeType:          string  = '';

  constructor( public encrypt:   EncryptService
             , public http:      HttpClient
             , public list:      ListService
             , public log:       LogService
             , public message:   MessageService
             , public operation: OperationService
             , public person:    PersonService
             , public router:    RouterService
             , public session:   SessionInfo
             ) {
  }

  async read() {
    let jsonParams = { 'Endpoint'  : this.session.endpoint
                     , 'Token'     : this.session.token
                     , 'Operation' : 'readPhoto'
                     , 'Fields'    : [ { 'name' : 'PersonID', 'value' : this.list.getValue( this.session.personData, 'PersonID', true ) }
                                     , { 'name' : 'Resized',  'value' : 'False'                                                         }
                                     ]
                     };
    let result = await this.operation.executeOperation( 'operation', jsonParams );

    if ( result['Result']['Code'] ) {
      if ( result['Result']['Code'] == '0' ) {
        let photoresultset     = result['Result']['ResultSets'];

        this.publishPermission = this.list.getValue( photoresultset, 'PublishPermission' ) == 'True';
        this.photoResult       = this.list.getValue( photoresultset, 'Photo', true );
        this.photoAgreed       = this.photo !== null
      }
    }

    if ( this.session.photoMiniatureWidth !== null && this.photo !== null ) {
      jsonParams = { 'Endpoint'  : this.session.endpoint
                   , 'Token'     : this.session.token
                   , 'Operation' : 'readPhoto'
                   , 'Fields'    : [ { 'name' : 'PersonID', 'value' : this.list.getValue( this.session.personData, 'PersonID', true ) }
                                   , { 'name' : 'Resized',  'value' : 'True'                                                          }
                                   ]
                   };
      result = await this.operation.executeOperation( 'operation', jsonParams );

      if ( result['Result']['Code'] ) {
        if ( result['Result']['Code'] == '0' ) {
          let photoresultset = result['Result']['ResultSets'];
          this.photoMiniature    = this.list.getValue( photoresultset, 'Photo', true );
        }
      }
    }
  }

  async save( permissions = false ) {
    this.router.toggleshowwait( true );

    this.saving = true;

    let resultpage = permissions ? 'photopermissions' : 'photo';

    if ( this.photoResult != undefined && this.photoResult != '' && !this.list.emptyList( this.session.personData ) ) {

      if ( this.photoResult.length < 10000 ) {
        this.message.setErrorMessage ( resultpage + '.result.failure', false, true );
        this.log.log( ( this.webcamPhoto ? ' webcam' : ' upload '+ this.mimeType ) + ' ' + this.photoResult.length + ' failed: ' + 'Photo too small', 1 );
        return;
      }

      this.firstphoto = this.list.getValue( this.session.personData, 'Photo', true ) == '';

      let operation = this.firstphoto ? 'createPhoto' : 'updatePhoto';

      let fields = [ { 'name' : 'UserID', 'value' : '0' }
                   , { 'name' : 'PersonID', 'value' :  this.list.getValue( this.session.personData, 'PersonID', true ) }
                   ]

      if ( this.session.publishPermission ) {
        fields.push ( { 'name' : 'PublishPermission', 'value' :  this.publishPermission } );
      }

      if ( this.session.photoMiniatureWidth != null ) {
        fields.push ({ 'name' : 'Photo', 'value' :  this.photoMiniature.replace('data:image/jpeg;base64,', '') });
        fields.push ({ 'name' : 'OriginalPhoto', 'value' :  this.photoResult.replace('data:image/jpeg;base64,', '') });
      } else {
        fields.push ({ 'name' : 'Photo', 'value' :  this.photoResult.replace('data:image/jpeg;base64,', '') });
      }

      let jsonParams = { 'Endpoint'  : this.session.endpoint
                       , 'Token'     : this.session.token
                       , 'Operation' : operation
                       , 'Fields'    : fields
                       };

      let result = await this.operation.executeOperation( 'operation', jsonParams );

      if ( result['Result']['Code'] ) {
        if ( result['Result']['Code'] == '0' ) {
          let personFound = await this.person.getPersonData();

          if ( personFound ) {
            this.message.setSuccessMessage ( resultpage + '.result.success', false, true );
            this.log.log( operation + ' ' + (this.webcamPhoto ? 'webcam' : 'upload '+ this.mimeType ) + ' ' + this.photoResult.length + ' succeeded', 3 );
          } else {
            this.message.setErrorMessage ( resultpage + 'result.failure', false, true );
            this.log.log( operation + (this.webcamPhoto ? ' webcam' : ' upload '+ this.mimeType ) + ' ' + this.photoResult.length + ' failed: ' + 'Person data not found', 1 );
          }
        } else {
          this.message.setErrorMessage ( resultpage + '.result.failure', false, true );
          this.log.log( operation + (this.webcamPhoto ? ' webcam' : ' upload '+ this.mimeType ) + ' ' + this.photoResult.length + ' failed: ' + result['Result']['Code'] + ' - ' + result['Result']['Description'], 1 );
        }
      } else {
        this.message.setErrorMessage ( resultpage + '.result.failure', false, true );
        this.log.log( operation + (this.webcamPhoto ? ' webcam' : ' upload '+ this.mimeType ) + ' ' + this.photoResult.length + ' failed: ' + JSON.stringify(result), 1 );
      }
    } else {
      this.message.setErrorMessage ( resultpage + '.result.failure', false, true );
    }

    this.saving = false;

    if ( permissions ) {
      this.router.goTo( 'photopermissions' );
      this.router.toggleshowwait(false);
    } else {
      this.router.goTo('result');
    }
  }

  togglePhotoAgreed() {
    this.photoAgreed = !this.photoAgreed;
  }

  togglePublishPermission() {
    this.publishPermission = !this.publishPermission;
  }

  async savetofile() {
    this.router.toggleshowwait( true );

    this.saving = true;

    if ( this.photoResult != undefined && this.photoResult != '' && !this.list.emptyList( this.session.personData ) ) {

      if ( this.photoResult.length < 10000 ) {
        this.message.setErrorMessage ( 'photo.result.failure', false, true );
        return;
      }

      let jsonParams = { 'file'    : this.list.getValue( this.session.personData, 'CardholderProfile', true )+'/'+this.list.getValue( this.session.personData, 'PersonNumber', true )
                       , 'photo'   : this.photoResult.replace('data:image/jpeg;base64,', '')
                       , 'what'    : 'savephoto'
                       }

      let params = await this.encrypt.encrypt(jsonParams);

      let url = environment.production ? environment.apiurl : 'wsdl';

      try {
        this.headers.set('Access-Control-Allow-Origin', '*');
        await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        this.message.setSuccessMessage ( 'photo.result.success', false, true );

        this.session.personData['Photo']             = { 'Value' : await this.getfile() };

      } catch(e) {
        this.message.setErrorMessage ( 'photo.result.failure', false, true );
      }

      this.saving = false;
      this.router.goTo('result');
    }
  }

  async getfile() {
    let jsonParams = { 'file'    : this.list.getValue( this.session.personData, 'CardholderProfile', true )+'/'+this.list.getValue( this.session.personData, 'PersonNumber', true )
                     , 'what'    : 'getphoto'
                     }

    let params = await this.encrypt.encrypt(jsonParams);

    let url = environment.production ? environment.apiurl : 'wsdl';

    try {
      this.headers.set('Access-Control-Allow-Origin', '*');
      let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      return result;
    } catch(e) {
      return '';
    }

  }
}
