import { Injectable }            from '@angular/core';
import { DataService }           from '@omnipas/services/data/data.service';
import { EncryptService }        from '@omnilib/services/encrypt/encrypt.service';
import { LanguageService }       from '@omnipas/services/language/language.service';
import { ListService }           from '@omnilib/services/list/list.service';
import { LoadingService }        from '@omnipas/services/loading/loading.service';
import { LogService }            from '@omnipas/services/log/log.service';
import { MailService }           from '@omnilib/services/mail/mail.service';
import { MessageService }        from '@omnipas/services/message/message.service';
import { OperationService }      from '@omnilib/services/operation/operation.service';
import { PageHeaderService }     from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }         from '@omnipas/services/router/router.service';
import { SessionInfo }           from '@omnipas/services/sessioninfo/sessioninfo';

import { differenceInMinutes, format, sub, toDate  }         from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  initialized:    boolean  = false;
  mfacode:        string   = '';
  mfatime                  = sub(toDate( new Date()),{'days':1 });

  constructor( public  data:           DataService
             , public  encrypt:        EncryptService
             , public  language:       LanguageService
             , public  list:           ListService
             , public  loading:        LoadingService
             , public  log:            LogService
             , public  mail:           MailService
             , public  message:        MessageService
             , public  pageheader:     PageHeaderService
             , public  session:        SessionInfo
             , public  operation:      OperationService
             , public  router:         RouterService
             ) {
  }

  async initialize() {
    this.router.toggleshowwait( true );
    this.mfacode = '';
    this.mfatime = sub(toDate( new Date()),{'days':1 });
    this.initialized = false;
    this.router.toggleshowwait( false );
  }

  async newDatabaseLogin( $username, $password ) {
    this.router.toggleshowwait( true );

    let jsonParams = { 'Endpoint'  : this.session.endpoint
                     , 'Token'     : this.session.token
                     , 'Operation' : 'readPersonLoginData'
                     , 'Fields'   : [ { 'name' : 'LoginID',  'value' : $username }
                                    , { 'name' : 'Password', 'value' : $password } ]
                     };

    let result     = await this.operation.executeOperation( 'operation', jsonParams );

    if ( result['Result']['Code'] != undefined && result['Result']['Code'] == '0' && result['Result']['ResultSets'] != undefined ) {
      let loginData = result['Result']['ResultSets'];

      console.log ( loginData );
      this.session.loginID        = this.list.getValue(loginData, 'LoginID');
      this.session.cardholderData = loginData;
      this.session.personData     = loginData;

      if ( this.session.MFAemail || this.session.MFAsms ) {
        await this.generateMFA();
      } else {
        this.session.loggedIn     = true;
        this.session.loginChecked = true;
        //await this.loading.postLoad();
        this.router.goTo('welcome');
      }
    } else {
      this.session.loginID    = '';
      this.session.languageID = '';
      this.session.loggedIn   = false;
      this.message.setErrorMessage ( 'login.error.invalid', true, true);
      this.router.toggleshowwait( false );
    }
  }

  async login( $username, $password ) {
    this.router.toggleshowwait( true );

    await this.initialize();

    let hash: string = '';
    let check: boolean = false;

    if ( $username != '' && $password != '' ) {
      if ( this.session.savephotoonserver && this.session.useFramework ) {
        if ( $password == this.session.password ) {
          let claims = await this.data.getdata( $username );

          if ( typeof claims == 'undefined' ) {
            this.session.loginID    = '';
            this.session.languageID = '';
            this.session.loggedIn   = false;
            this.message.setErrorMessage ( 'login.error.invalid', true, true);
          } else {
            this.session.loginID = claims['uids'][0];
            await this.loading.getDataFromClaims( claims );
            this.router.goTo('welcome');
          }
        } else {
          this.session.loginID    = '';
          this.session.languageID = '';
          this.session.loggedIn   = false;
          this.message.setErrorMessage ( 'login.error.invalid', true, true);
        }
      } else {

        let jsonParams = { 'Endpoint'  : this.session.endpoint
                         , 'Token'     : this.session.token
                         , 'Operation' : 'readCardholderLoginData'
                         , 'Fields'   : [ { 'name' : 'LoginID', 'value' : $username } ]
                         };

        let result     = await this.operation.executeOperation( 'operation', jsonParams );

        if ( result['Result']['Code'] != undefined && result['Result']['Code'] == '0' ) {

          let loginData = result['Result']['ResultSets'];

          this.session.loginID = this.list.getValue(loginData, 'LoginID');

          if ( this.session.loginID != '' ) {
            if ( this.session.useFramework ) {
              check = $password == this.session.password;
            } else {
              check = await this.encrypt.compare( $password, this.list.getValue(loginData, 'CardholderPassword') );
            }
          }

          if ( check ) {
            this.session.cardholderData = loginData;

            if ( this.session.MFAemail || this.session.MFAsms ) {
              await this.generateMFA();
            } else {
              this.session.loggedIn     = true;
              this.session.loginChecked = true;
              await this.loading.postLoad();
              this.router.goTo('welcome');
            }
          } else {
            this.session.loginID    = '';
            this.session.languageID = '';
            this.session.loggedIn   = false;
            this.message.setErrorMessage ( 'login.error.invalid', true, true);
            //this.pageheader.setPageHeader( 'pagetitle.login');
          }
        } else {
          this.session.loginID    = '';
          this.session.languageID = '';
          this.session.loggedIn   = false;
          this.message.setErrorMessage ( 'login.error.invalid', true, true);
        }
      }
    } else {
      this.session.loginID    = '';
      this.session.languageID = '';
      this.session.loggedIn   = false;
      this.message.setErrorMessage ( 'login.error.incomplete', true, true);
    }
  }

  async generateMFA() {
    this.router.toggleshowwait( true );

    this.mfacode = Math.round((Math.random() * (999999 - 111111) + 111111)).toString();

    if ( this.session.MFAemail && this.list.getValue(this.session.cardholderData, 'Email') != '' ) {
      const response = '<a href="mailto:'+this.session.emailProvider['response']+'">'+this.session.emailProvider['response']+'</a>';
      const body     = '<p>'  + this.language.getTranslation( 'logincheck.email.line1' )
                     + '<br>' + this.language.getTranslation( 'logincheck.email.line2' )
                     + '</p>'
                     + '<p><b>' + this.mfacode + '</b></p>'
                     + '<p>'  + this.language.getTranslation( 'logincheck.email.line3' ) + '</p>'
                     ;

      await this.mail.send ( this.list.getValue(this.session.cardholderData, 'Email')
                           , this.language.getTranslation( 'logincheck.email.subject' )
                           , body
                           );
    }

    this.mfatime = toDate(new Date());
    this.session.loggedIn     = true;
    this.session.loginChecked = false;

    this.router.goTo('logincheck');
  }

  async mfacheck( $code ) {
    this.router.toggleshowwait( true );
    if ( differenceInMinutes(toDate(new Date()), this.mfatime) > 2 ) {
      this.session.loginID      = '';
      this.session.loggedIn     = false;
      this.session.loginChecked = false;
      this.message.setErrorMessage ( 'logincheck.error.expired', true, true );
    } else if ( $code == '' || this.mfacode == '' || $code != this.mfacode ) {
      this.session.loginID      = '';
      this.session.loggedIn     = false;
      this.session.loginChecked = false;
      this.message.setErrorMessage ( 'logincheck.error.invalid', true, true );
    } else {
      await this.loading.postLoad();
      this.session.loggedIn     = true;
      this.session.loginChecked = true;
      this.session.loginChecked = false;
      this.router.goTo('welcome');
    }
  }

  logout() {
    this.initialize();
  }

}
