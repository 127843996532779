import { Injectable }              from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptService }          from '@omnilib/services/encrypt/encrypt.service';
import { environment }             from 'libs/omnilib/src/environments/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  headers: HttpHeaders = new HttpHeaders;
  deviceInfo: string = '';

  constructor ( public encrypt:  EncryptService
              , public http:     HttpClient
              ) {}


  async getdata( $filename: string ) {
    let url        = ( environment.production ? environment.apiurl : 'wsdl' );
    let jsonParams = { 'what' : 'getdata'
                     , 'file' : $filename
                     };
    let params     = await this.encrypt.encrypt(jsonParams);

    try {
      let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      return await JSON.parse ( await this.encrypt.decrypt ( result ) );
    } catch(e) {
    }
  }

}
