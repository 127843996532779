import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { EncryptService } from '@omnilib/services/encrypt/encrypt.service';
import { XMLService } from '@omnilib/services/xml/xml.service';
import { environment } from 'libs/omnilib/src/environments/dev/environment';
import { format, toDate  } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class InitializeService {

  constructor ( public  encrypt: EncryptService
              , public  http:    HttpClient
              , public  xml:     XMLService
              ) {
  }

  headers: HttpHeaders = new HttpHeaders;

  async initializeConnection( $params ) {

    this.headers.set('Access-Control-Allow-Origin', '*');

    let result = {};
    result['Result']                = {};
    result['Result']['Code']        = '';
    result['Result']['Description'] = '';
    result['SessionID']             = this.createUniqueSessionID();

    let params = $params;

    let config = await this.getConfig(environment.configfile);

    if ( config == this.noconnection() ) {
      return config;
    } else {
      for ( let key in config ) {
        result[key] = config[key];
      }
    }

    if ( typeof config != 'undefined' ) {
      result['Endpoint']    = '[soapurl]';
      result['VersionInfo'] = await this.getVersionInfo(result['Endpoint']);
      result['SessionData'] = await this.createSession(result['Endpoint'], config['sessionUsername'], config['sessionPassword'], result['SessionID'] );
      result['Token']       = result['SessionData']['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:CreateSessionResponse']['NS2:TTokenObject']['Token']['Value'];

      if ( typeof result['Token'] != 'string' || result['Token']  == '' ) {
        result['Result']['Code']        = '999';
        result['Result']['Description'] = 'Error getting token';
      } else {
        result['LicenseInfo'] = await this.getLicenseInfo( result['Endpoint'], result['Token'] );
      }
    }

    if ( result['Result']['Code'] == '' ) {
      result['Result']['Code']        = '0';
      result['Result']['Description'] = 'Initialization successful';
    }

    return result;
  }

  async getConfig($configfile) {
    if ( environment.apiurl != '' && environment.configfile != '' ) {
      let url = ( environment.production ? environment.apiurl : 'wsdl' );

      let jsonParams = { 'what' : 'conf'
                       , 'file' : $configfile
                       };

      let params     = await this.encrypt.encrypt(jsonParams);

      try {
        let result   = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        return await JSON.parse ( await this.encrypt.decrypt ( result ) );
      } catch(e) {
        return this.noconnection();
      }
    } else {
      return environment.config;
    }
    return this.noconnection();
  }

  async getConfList() {
    if ( environment.apiurl != '' ) {
      let url        = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'what' : 'conflist' };
      let params     = await this.encrypt.encrypt(jsonParams);
      try {
        let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        return await this.encrypt.decrypt ( result );
      } catch(e) {
        return '';
      }
    } else {
      return '';
    }
    return '';
  }

  /*
  async getWSDL( wsdlurl ): Promise<any> {
    if ( wsdlurl['server'] != '' && wsdlurl['instance'] != '' ) {
      let url = '';
      let params = {};

      if ( environment.apiurl != '' ) {
        url = environment.production ? environment.apiurl : 'wsdl';

        let jsonParams = { 'url'  : '[wsdlurl]'
                         , 'what' : 'wsdl'
                         }

        params = await this.encrypt.encrypt(jsonParams);

        console.log ( params );

        try {
          let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
          return await this.xml.xmlStringToJson ( await this.encrypt.decrypt ( result ) );
        } catch(e) {
          return this.noconnection;
        }
      } else {
        url = ( environment.production ? wsdlurl['server'] : 'wsdl' )+'/'+wsdlurl['instance'];
        try {
          let result = await this.http.post( url, {}, { responseType: 'text' } ).toPromise();
          return await this.xml.xmlStringToJson ( result );
        } catch(e) {
          return this.noconnection();
        }
      }
    } else {
      return this.noconnection();
    }
    return this.noconnection();
  }
  */

  async getVersionInfo($endpoint): Promise<any> {
    let xml = this.xml.createXML('GetVersionInfo', null);

    if ( environment.apiurl != '' ) {
      let url = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'url' : $endpoint
                       , 'xml' : xml
                       , 'what' : 'soapcall'
                       }

      let params = await this.encrypt.encrypt(jsonParams);

      try {
        let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        return await this.xml.xmlStringToJson( await this.encrypt.decrypt ( result ), true )['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:GetVersionInfoResponse'];
      } catch(e) {
        console.log ( e );
        return this.noconnection();
      }
    } else {
      try {
        let result = await this.http.post( $endpoint, xml, { responseType: 'text' } ).toPromise();
        return await this.xml.xmlStringToJson ( result )['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:GetVersionInfoResponse'];
      } catch(e) {
        return this.noconnection();
      }
    }
    return this.noconnection();
  }

  async createSession( $endpoint, $username, $password, $sessionid ): Promise<any> {
    const args = { 'UserName': $username
                 , 'Password': $password
                 , 'UserIP':   $sessionid
                 };

    let xml    = this.xml.createXML('CreateSession', args);

    if ( environment.apiurl != '' ) {
      let url = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'url' : $endpoint
                       , 'xml' : xml
                       , 'what' : 'soapcall'
                       }
      let params = await this.encrypt.encrypt(jsonParams);
      try {
        let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        return await this.xml.xmlStringToJson( await this.encrypt.decrypt ( result ), true );
      } catch(e) {
        return this.noconnection();
      }
    } else {
      try {
        let result = await this.http.post( $endpoint, xml, { responseType: 'text' } ).toPromise();
        return await this.xml.xmlStringToJson ( result );
      } catch(e) {
        return this.noconnection();
      }
    }
    return this.noconnection();
  }

  createUniqueSessionID() {
    let uid = format(toDate(new Date),'MMddHHmmssSS');
    return uid.substr(0,3) + '.' + uid.substr(3,3) + '.' + uid.substr(6,3) + '.' + uid.substr(9);
  }

  async getLicenseInfo($endpoint, $token): Promise<any>  {
    const args = { 'Token': $token };
    let xml    = this.xml.createXML('GetLicenseInfo', args);

    if ( environment.apiurl != '' ) {
      let url = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'url' : $endpoint
                       , 'xml' : xml
                       , 'what' : 'soapcall'
                       }
      let params = await this.encrypt.encrypt(jsonParams);
      try {
        let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        return await this.xml.xmlStringToJson( await this.encrypt.decrypt ( result ), true )['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:GetLicenseInfoResponse'];
      } catch(e) {
        return this.noconnection();
      }
    } else {
      try {
        let result = await this.http.post( $endpoint, xml, { responseType: 'text' } ).toPromise();
        return await this.xml.xmlStringToJson(result)['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:GetLicenseInfoResponse'];
      } catch(e) {
        return this.noconnection();
      }
    }
    return this.noconnection();
  }

  noconnection() {
    let result = {};
    result['Result']                = {};
    result['Result']['Code']        = '999';
    result['Result']['Description'] = 'No connection possible';
    return result;
  }
}
