<div
  *ngIf="initialized && !photo.saving"
  class="w3-container w3-margin-top"
  [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'"
  (window:resize)="onResize($event)"
>
  <omnipas-pageheader></omnipas-pageheader>
  <image-cropper
    [class]="!photoSelected && !message.error ? 'show' : 'hide'"
    #myPhoto
    id="myPhoto"
    [imageBase64]="photo.photo"
    [maintainAspectRatio]="true"
    [autoCrop]="true"
    [aspectRatio]="3 / 4"
    resizeToWidth="{{ session.photoResizeToWidth }}"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  >
  </image-cropper>
  <div [class]="!photoSelected && !message.error ? 'showrotation' : 'hide'">
    <fa-icon
      [icon]="icons.faundo"
      class="w3-left"
      (click)="rotateLeft()"
      title="{{ 'buttons.rotateleft' | translate: session.transparams }}"
    ></fa-icon>
    <fa-icon
      [icon]="icons.faredo"
      class="w3-right"
      (click)="rotateRight()"
      title="{{ 'buttons.rotateright' | translate: session.transparams }}"
    ></fa-icon>
  </div>
  <div
    class="w3-container w3-center w3-mobile"
    [class]="photoSelected ? 'show' : 'hide'"
  >
    <img
      #myFace
      id="myFace"
      class="w3-image w3-mobile photoResult"
      [src]="croppedImage"
    />
  </div>
  <div class="w3-margin" *ngIf="!photoSelected">
    <input
      id="back"
      name="back"
      type="button"
      class="w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      value="{{ 'buttons.back' | translate: session.transparams }}"
      title="{{ 'buttons.back' | translate: session.transparams }}"
      (click)="back()"
    />
    <input
      id="next"
      name="next"
      type="button"
      class="w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      [disabled]="message.error ? 'disabled' : ''"
      value="{{ 'buttons.next' | translate: session.transparams }}"
      title="{{ 'buttons.next' | translate: session.transparams }}"
      (click)="select()"
    />
  </div>
  <div
    class="w3-margin"
    *ngIf="photoSelected && session.photoAgreedNotOnFirstPage"
  >
    <input
      id="agreed"
      name="agreed"
      type="checkbox"
      class="w3-check w3-check-theme w3-margin-right"
      title=""
      (click)="photo.togglePhotoAgreed()"
      [checked]="photo.photoAgreed ? 'checked' : ''"
      tabindex="1"
    />
    <label for="agreed">{{
      'welcome.agreed' | translate: session.transparams
    }}</label>
  </div>
  <div class="w3-margin" *ngIf="photoSelected && session.publishPermission">
    <input
      id="publishPermission"
      name="publishPermission"
      type="checkbox"
      class="w3-check w3-margin-right"
      title="{{
        'photo.edit.publishPermission' | translate: session.transparams
      }}"
      (click)="photo.togglePublishPermission()"
      [checked]="photo.publishPermission ? 'checked' : ''"
    />
    <label for="publishPermission">{{
      'photo.edit.publishPermission' | translate: session.transparams
    }}</label>
    <span *ngIf="session.changepublishpermission">&nbsp;<a class="link" innerHTML="{{ 'photo.edit.moreinfo' | translate: session.transparams }}" (click)="showpopup()"></a></span>
  </div>
  <div class="w3-margin" *ngIf="photoSelected">
    <input
      id="retry"
      name="retry"
      type="button"
      class="w3-button w3-border w3-button-theme w3-margin-right pageButton w3-round"
      value="{{ 'buttons.retry' | translate: session.transparams }}"
      title="{{ 'buttons.retry' | translate: session.transparams }}"
      (click)="retry()"
    />
    <input
      id="save"
      name="save"
      type="button"
      class="w3-button w3-border w3-button-theme pageButton w3-round"
      [disabled]="message.error ? 'disabled' : ''"
      value="{{ 'buttons.save' | translate: session.transparams }}"
      title="{{ 'buttons.save' | translate: session.transparams }}"
      (click)="save()"
      [disabled]="
        !photo.photoAgreed && session.photoAgreedNotOnFirstPage
          ? 'disabled'
          : ''
      "
    />
  </div>
</div>
