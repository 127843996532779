<div class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.success && !message.error" class="w3-margin">
    <p>{{ 'requestcard.sure' | translate: session.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "submit"
      value       = "{{ 'buttons.yes' | translate: session.transparams }}"
      title       = "{{ 'buttons.yes' | translate: session.transparams }}"
      (click)     = "submit()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "cancel"
      value       = "{{ 'buttons.no' | translate: session.transparams }}"
      title       = "{{ 'buttons.no' | translate: session.transparams }}"
      (click)     = "cancel()"
    >
  </div>
  <div *ngIf="message.success || message.error" class="w3-margin">
    <p>{{ 'requestcard.logout' | translate: session.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: session.transparams }}"
      title       = "{{ 'buttons.logout' | translate: session.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
