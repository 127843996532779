import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }       from '@omnipas/services/card/card.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-deblockcard',
  templateUrl: './deblockcard.component.html'
})
export class DeblockCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  showLogout:  boolean = false;

  constructor ( public card:       CardService
              , public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.deblockcard', 'pagetext.deblockcard' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  async deblockcard() {

    this.router.toggleshowwait( true );

    if ( await this.card.update(5) ) {
      this.message.setSuccessMessage('deblock.success');
    } else {
      this.message.setErrorMessage('deblock.failure');
    }

    this.router.toggleshowwait( false );
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
