import { Injectable } from '@angular/core';
import { environment } from 'libs/omnilib/src/environments/dev/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class XMLService {
  constructor( ) {
  }

  createXML(name, args, bota = false) {

    let xml = '<?xml version="1.0" encoding="utf-8"?>'
            + '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:tns="http://tempuri.org/" xmlns:ns1="urn:OmniCMS_SOAPIntf"'
            + ( bota ? ' soap:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"' : '' )
            + '>'
            + '<soap:Body><tns:'+name+'>';

    if ( args != null ) {
      Object.entries(args).forEach(
        ([key, value]) => { xml += '<'+key+( key == 'FileContent' && bota ? ' xsi:type="xsd:base64Binary"' : '' ) +'>'+value+'</'+key+'>'; }
      );
    }

    xml    += '</tns:'+name+'></soap:Body>'
            + '</soap:Envelope>'
            ;

    return xml;
  }

  xmlStringToJson(string, soapcall = false ) {
    if ( environment.apitype == 'php' || soapcall ) {
      // Convert the XML string to an XML Document.
      //const oParser = new xmldom.DOMParser();
      const oParser = new DOMParser();
      const oDOM = oParser.parseFromString(string, "application/xml");
      // Convert the XML Document to a JSON Object.
      return this.xmlToJson(oDOM);
    } else {
      return JSON.parse(string);
    }
  }

  xmlToJson(xml) {
    let obj = new Object();

    if ( xml.nodeType == 1 && xml.attributes.length > 0 ) {
      //obj["@attributes"] = {};
      for ( let j = 0; j < xml.attributes.length; j++ ) {
        let attribute = xml.attributes.item(j);

        if ( attribute.nodeName == 'xsi:type' ) {
          obj['Type'] = attribute.nodeValue.replace('xsd:','').replace('NS2:','').toUpperCase();
        } else {
          obj[attribute.nodeName] = attribute.nodeValue;
        }
      }
    }

    if ( xml.hasChildNodes() ) {
      for( let i = 0; i < xml.childNodes.length; i++ ) {
        let item = xml.childNodes.item(i);

        if ( item.nodeName == '#text' || item.nodeType == 3 ) {

          if ( item.nodeValue.trim().replace('\r\n', '') !== '' ) {
            obj['Value'] = item.nodeValue;
          }
        } else {

          if ( typeof(obj[item.nodeName]) == "undefined" ) {
            obj[item.nodeName] = this.xmlToJson(item);
          } else {
            if ( typeof(obj[item.nodeName].push) == "undefined" ) {
              let old = obj[item.nodeName];
              obj[item.nodeName] = [];
              obj[item.nodeName].push(old);
            }
            obj[item.nodeName].push(this.xmlToJson(item));
          }
        }
      }
    }

    obj['undefined'] = null;
    delete obj['undefined'];

    return obj;
  }
}
