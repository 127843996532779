<div class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.success && !message.error" class="w3-margin">
    <div class="w3-margin-bottom">
      <div *ngFor="let format of session.nameoncardformats">
        <input type="radio" name="format" id="format_{{format.id}}" value="{{format.format}}" [checked]="setSelected(format.format)">&nbsp;<label for="format_{{format.id}}">{{format.format}}</label>
      </div>
    </div>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "submit"
      value       = "{{ 'buttons.save' | translate: session.transparams }}"
      title       = "{{ 'buttons.save' | translate: session.transparams }}"
      (click)     = "submit()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "cancel"
      value       = "{{ 'buttons.back' | translate: session.transparams }}"
      title       = "{{ 'buttons.back' | translate: session.transparams }}"
      (click)     = "cancel()"
    >
  </div>
  <div *ngIf="message.success || message.error" class="w3-margin">
    <p>{{ 'requestcard.logout' | translate: session.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: session.transparams }}"
      title       = "{{ 'buttons.logout' | translate: session.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
