import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-pageheader',
  templateUrl: './pageheader.component.html'
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  constructor ( public pageheader: PageHeaderService
              , public session:    SessionInfo
              ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    return;
  }

}
