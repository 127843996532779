<div #cardpreviewcontainer id="cardpreviewcontainer" class="w3-margin" *ngIf="initialized">
  <div
    #cardpreview
    id      = "cardpreview"
    class   = "cardpreview"
    [class] = "getClass()"
    (click) = "showInfo()"
    title   = "{{ title | translate: session.transparams }}"
  >
    <img *ngIf="currentPhoto != ''" class="photo" alt="photo" src="{{currentPhoto}}">
    <div class="name">
      <div class="title"       >{{ list.getValue( this.session.personData, 'PersonTitle', true ) }}</div>
      <div class="initials"    >{{ list.getValue( this.session.personData, 'Initials'   , true ) }}</div>
      <div class="firstname"   >{{ list.getValue( this.session.personData, 'FirstName'  , true ) }}</div>
      <div class="middlename"  >{{ list.getValue( this.session.personData, 'MiddleName' , true ) }}</div>
      <div class="lastname"    >{{ list.getValue( this.session.personData, 'LastName'   , true ) }}</div>
      <div class="nameoncard"  >{{ list.getValue( this.session.personData, 'NameOnCard' , true ) }}</div>
    </div>
    <div class="loginid"             >{{ list.getValue( this.session.personData, 'LoginID' , true ) }}</div>
    <div class="group"               >{{ list.getValue( this.session.personData, 'CardholderProfile', true ) }}</div>
    <div class="departement"         >{{ list.getValue( this.session.personData, 'DepartmentName'   , true ) }}</div>
    <div class="location"            >{{ list.getValue( this.session.personData, 'LocationName'     , true ) }}</div>
    <div class="personnumber"        >{{ list.getValue( this.session.personData, 'PersonNumber'     , true ) }}</div>
    <div class="profilenumber"       >{{ list.getValue( this.session.personData, 'ProfileNumber'    , true ) }}</div>
    <div class="cardnumber"          >{{ list.getValue( this.session.personData, 'CardNumber'       , true ) }}</div>
    <div class="startdate"           >{{ startdate }}</div>
    <div class="enddate"             >{{ list.getValue( this.session.personData, 'CardEndDate    '  , true ) }}</div>
    <div class="expdate"             >{{ expdate }}</div>
    <div class="cardholderstartdate" >{{ cardholderstartdate }}</div>

    <omnicard-barcode class="barcode" code="{{list.getValue( this.session.personData, 'CardNumber'       , true )}}" displayvalue="true"></omnicard-barcode>
  </div>
</div>
