import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }      from '@omnipas/services/icons/icons.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-moreinfo',
  templateUrl: './moreinfo.component.html'
})
export class MoreinfoComponent implements OnInit, OnDestroy {

  params;

  constructor ( public icons:   IconsService
              , public router:  RouterService
              , public session: SessionInfo
              ) {
  }

  ngOnInit() {
  }

  close() {
    this.router.showmoreinfo = false;
  }

  showDisclaimer() {
  }

  showTerms() {
  }

  ngOnDestroy() {
    return;
  }
}
