import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
import { IconsService }      from '@omnipas/services/icons/icons.service';
import { LanguageService }   from '@omnipas/services/language/language.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PhotoService }      from '@omnipas/services/photo/photo.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';
import { format, parse  }    from 'date-fns';

@Component({
  selector: 'omnipas-digitalcard',
  templateUrl: './digitalcard.component.html'
})
export class DigitalCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  invalidLink: boolean = false;
  showwhat:    string  = 'pass';

  blockwidth:  string  = '0px';
  blockheight: string  = '0px';

  expdate:     string  = '';
  startdate:   string  = '';

  constructor ( public  icons:      IconsService
              , public  language:   LanguageService
              , public  list:       ListService
              , public  loading:    LoadingService
              , public  message:    MessageService
              , public  pageheader: PageHeaderService
              , public  photo:      PhotoService
              , public  route:      ActivatedRoute
              , public  router:     RouterService
              , public  session:    SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    if (    this.session.loginID == ''
         || this.list.emptyList( this.session.cardholderData )
         || this.list.emptyList( this.session.personData )
       ) {

      const params = await this.route.params['value'];

      if ( params['token1'] && params['token1'] != '' ) {
        this.session.loginID = decodeURIComponent(params['token1']);
        await this.loading.preLoad();
        await this.loading.postLoad();
      } else {
        this.message.setErrorMessage( 'digitalcard.error.noactivecard' );
      }
    }

    try {
      this.expdate = format( parse( this.list.getValue( this.session.personData, 'CardEndDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.expdate = '';
    }

    try {
      this.startdate = format( parse( this.list.getValue( this.session.personData, 'CardStartDate', true ), "dd-MM-yyyy HH:mm:ss", new Date() ), "dd-MM-yyyy" );
    } catch(e) {
      this.startdate = format( new Date(), "dd-MM-yyyy");
    }

    this.pageheader.setPageHeader( '', '' );

    this.blockwidth  = ( Math.min ( this.router.mainwidth, this.router.mainheight ) / 2 ) + 'px';
    this.blockheight = ( Math.min ( this.router.mainwidth, this.router.mainheight ) / 4  ) + 'px';

    this.router.showdigitalcard = true;
    this.initialized = true;
  }

  close() {
    this.router.goHome();
  }

  ngOnDestroy() {
    this.router.goHome();
  }

  getClass() {
     let classList = this.list.getValue( this.session.personData, 'CardholderProfile', true ).toLowerCase();
     classList += this.session.langimage ? ' ' + this.language.currentLanguage : '';
     return classList;
  }

  getorientation() {
    return ( window.innerWidth > window.innerHeight ? 'landscape' : 'portrait' ) + ( window.innerWidth < 900 || window.innerHeight < 900 ? ' mobile' : ' desktop' );
  }

}
