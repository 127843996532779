import { Component, OnInit, OnDestroy } from '@angular/core';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PhotoService }      from '@omnipas/services/photo/photo.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-photoresult',
  templateUrl: './photoresult.component.html'
})
export class PhotoResultComponent implements OnInit, OnDestroy {

  initialized: boolean     = false;
  deliveryaddress: boolean = false;

  constructor ( public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public photo:      PhotoService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.photoresult' );
    }

    this.deliveryaddress  = this.message.success
                         && this.session.deliveryaddress
                         && this.photo.firstphoto
                         && ( this.list.getValue( this.session.personData, 'CardholderProfile', true ).toLowerCase() == 'student' );

    this.initialized     = true;
    this.router.toggleshowwait( false );
  }

  logout() {
    this.router.logout();
  }

  back() {
    this.router.goTo('photo');
  }

  continue() {
    this.router.goTo('deliveryaddress');
  }

  ngOnDestroy() {
    return;
  }

}
