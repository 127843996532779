<div *ngIf="initialized && session.useADFS" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.error" class="w3-margin">
    <input
      type        = "button"
      id          = "login"
      name        = "login"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      title       = "{{ 'buttons.adfs' | translate: session.transparams }}"
      value       = "{{ 'buttons.adfs' | translate: session.transparams }}"
      (click)     = "submit()"
    >
  </div>
</div>
