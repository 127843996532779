import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { NgModule }                         from '@angular/core';
import { FormsModule }                      from '@angular/forms';
import { HttpClient, HttpClientModule }     from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory }                from './core/services/language/http.loader.factory';
import { OAuthModule, OAuthModuleConfig }   from 'angular-oauth2-oidc';
import { ImageCropperModule }               from 'ngx-image-cropper';
import { QRCodeSVGModule }                  from 'ngx-qrcode-svg';
import { FontAwesomeModule }                from '@fortawesome/angular-fontawesome';

import { AppComponent }                from './app.component';
import { AppRoutingModule }            from './app-routing.module';
import { AboutComponent }              from './modules/about/about.component';
import { ActivateCardComponent }       from './modules/activatecard/activatecard.component';
import { ADFSLoginComponent }          from './modules/adfslogin/adfslogin.component';
import { BarcodeComponent }            from '@omnilib/modules/barcode/barcode.component';
import { BlockCardComponent }          from './modules/blockcard/blockcard.component';
import { BreadcrumbComponent }         from './modules/breadcrumb/breadcrumb.component';
import { CardInfoComponent }           from './modules/cardinfo/cardinfo.component';
import { CardPreviewComponent }        from './modules/cardpreview/cardpreview.component';
import { ChargingCardComponent }       from './modules/chargingcard/chargingcard.component';
import { CardStatusComponent }         from './modules/cardstatus/cardstatus.component';
import { DeblockCardComponent }        from './modules/deblockcard/deblockcard.component';
import { DeliveryAddressComponent }    from './modules/deliveryaddress/deliveryaddress.component';
import { DigitalCardComponent }        from './modules/digitalcard/digitalcard.component';
import { FatalErrorComponent }         from './modules/fatalerror/fatalerror.component';
import { FontToggleComponent }         from './modules/fonttoggle/fonttoggle.component';
import { FooterComponent }             from './modules/footer/footer.component';
import { HeaderComponent }             from './modules/header/header.component';
import { LanguageToggleComponent }     from './modules/languagetoggle/languagetoggle.component';
import { LicensePlateComponent }       from './modules/licenseplate/licenseplate.component';
import { LoadingComponent }            from './modules/loading/loading.component';
import { LoginComponent }              from './modules/login/login.component';
import { LoginCheckComponent }         from './modules/logincheck/logincheck.component';
import { LogoutComponent }             from './modules/logout/logout.component';
import { MaintainanceComponent }       from './modules/maintainance/maintainance.component';
import { MessageComponent }            from './modules/message/message.component';
import { MoreinfoComponent }           from './modules/moreinfo/moreinfo.component';
import { NameOnCardComponent }         from './modules/nameoncard/nameoncard.component';
import { PageHeaderComponent }         from './modules/pageheader/pageheader.component';
import { PaymentComponent }            from './modules/payment/payment.component';
import { PermissionsInfoComponent }    from './modules/permissionsinfo/permissionsinfo.component';
import { PhotoEditComponent }          from './modules/photoedit/photoedit.component';
import { PhotoPermissionsComponent }   from './modules/photopermissions/photopermissions.component';
import { PhotoRequirementsComponent }  from './modules/photorequirements/photorequirements.component';
import { PhotoResultComponent }        from './modules/photoresult/photoresult.component';
import { QRCodeComponent }             from '@omnilib/modules/qrcode/qrcode.component';
import { RequestCardComponent }        from './modules/requestcard/requestcard.component';
import { RequestPasswordComponent }    from './modules/requestpassword/requestpassword.component';
import { ResetPasswordComponent }      from './modules/resetpassword/resetpassword.component';
import { SamlComponent }               from './modules/saml/saml.component';
import { SurfLoginComponent }          from './modules/surflogin/surflogin.component';
import { ShowwaitComponent }           from './modules/showwait/showwait.component';
import { WebcamComponent }             from './modules/webcam/webcam.component';
import { WelcomeComponent }            from './modules/welcome/welcome.component';

import { CustomTitleCasePipe
       , CustomInitCapPipe }           from './core/pipes/pipes';

const authModuleConfig: OAuthModuleConfig = {
  // Inject "Authorization: Bearer ..." header for these APIs:
  resourceServer: {
    allowedUrls: ['https://localhost:8080'],
     sendAccessToken: true,
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ActivateCardComponent,
    ADFSLoginComponent,
    BarcodeComponent,
    BlockCardComponent,
    BreadcrumbComponent,
    CardInfoComponent,
    CardPreviewComponent,
    CardStatusComponent,
    ChargingCardComponent,
    DeblockCardComponent,
    DeliveryAddressComponent,
    DigitalCardComponent,
    FatalErrorComponent,
    FontToggleComponent,
    FooterComponent,
    HeaderComponent,
    LanguageToggleComponent,
    LicensePlateComponent,
    LoadingComponent,
    LoginComponent,
    LoginCheckComponent,
    LogoutComponent,
    MaintainanceComponent,
    MessageComponent,
    MoreinfoComponent,
    NameOnCardComponent,
    PageHeaderComponent,
    PaymentComponent,
    PermissionsInfoComponent,
    PhotoEditComponent,
    PhotoPermissionsComponent,
    PhotoResultComponent,
    PhotoRequirementsComponent,
    QRCodeComponent,
    RequestCardComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    SamlComponent,
    SurfLoginComponent,
    ShowwaitComponent,
    WebcamComponent,
    WelcomeComponent,
    CustomTitleCasePipe,
    CustomInitCapPipe
  ],
  imports: [
    BrowserModule,
    HammerModule,
    QRCodeSVGModule,
    FormsModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(authModuleConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageCropperModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
