import { Component, OnInit, OnDestroy }     from '@angular/core';
import { LoadingService }        from '@omnipas/services/loading/loading.service';
import { MessageService }        from '@omnipas/services/message/message.service';
import { PageHeaderService }     from '@omnipas/services/pageheader/pageheader.service';
import { PhotoService }          from '@omnipas/services/photo/photo.service';
import { RouterService }         from '@omnipas/services/router/router.service';
import { SessionInfo }           from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  removed:     boolean = false;

  constructor ( public  loading:        LoadingService
              , public  message:        MessageService
              , public  pageheader:     PageHeaderService
              , public  photo:          PhotoService
              , public  router:         RouterService
              , public  session:        SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;

    if ( this.session.useSurf ) {
      this.pageheader.setPageHeader( 'pagetitle.logout', 'pagetext.logout2' );
    } else {
      this.pageheader.setPageHeader( 'pagetitle.logout', 'pagetext.logout1' );
    }

    this.session.cardholderData = {};
    this.session.personData     = {};
    this.session.loginID        = '';

    this.photo.photo            = '';
    this.photo.photoResult      = '';

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async login() {
    this.router.toggleshowwait( true );
    this.session.token = '';
    await this.loading.preLoad();
    this.router.toggleshowwait( false );
    this.router.goToLogin();
  }

  ngOnDestroy() {
    return;
  }
}
