<footer class="w3-border w3-cell-row w3-mobile w3-theme-l3 w3-border-theme w3-round">
  <div class="w3-half w3-padding w3-responsive w3-mobile fullheight">
    <omnipas-fonttoggle *ngIf="session.togglefont"></omnipas-fonttoggle>
  </div>
  <div *ngIf="session.transparams.customer_shortname != ''" class="w3-half w3-right-align w3-padding w3-responsive w3-mobile">
    <a class="link" (click)="showAbout()" title="{{ 'header.about' | translate: session.transparams }}">{{ 'header.about' | translate: session.transparams }}</a>
    <br/>
    <span *ngIf="session.versioninfooter" class="w3-text-theme">{{version}}-{{hash}} {{date}}</span>
  </div>
</footer>
