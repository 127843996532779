import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }       from '@omnipas/services/card/card.service';
import { ListService }       from '@omnilib/services/list/list.service';
import { LoadingService }    from '@omnipas/services/loading/loading.service';
import { MessageService }    from '@omnipas/services/message/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader/pageheader.service';
import { PaymentService }    from '@omnipas/services/payment/payment.service';
import { RouterService }     from '@omnipas/services/router/router.service';
import { SessionInfo }       from '@omnipas/services/sessioninfo/sessioninfo';

@Component({
  selector: 'omnipas-requestcard',
  templateUrl: './requestcard.component.html'
})
export class RequestCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  constructor ( public card:       CardService
              , public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public payment:    PaymentService
              , public router:     RouterService
              , public session:    SessionInfo
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;

    if ( this.session.loginID == '' || this.list.emptyList( this.session.cardholderData ) || this.list.emptyList( this.session.personData ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.requestcard', 'pagetext.requestcard' );
    }

    this.initialized = true;
    this.router.toggleshowwait( false );

  }

  async submit() {
    this.router.toggleshowwait( true );
    if ( !this.list.emptyList ( this.session.cardprices ) && this.session.amount != '0000' ) {
      this.router.goTo("payment");
    } else {
      if ( await this.card.create() ) {
        this.message.setSuccessMessage ( 'requestcard.success');
      } else {
        this.message.setErrorMessage( 'requestcard.failure' );
      }
    }
    this.router.toggleshowwait( false );
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
